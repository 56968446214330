import * as Styled from "./styled";
import type { EscalationPointFields } from "@/services/api/types";

type Props = {
  fields: EscalationPointFields;
};

const EscalationPoint = ({ fields }: Props) => {
  return (
    <Styled.EscalationPoint>
      <Styled.ContactRow>
        <Styled.Name>{fields.Name}</Styled.Name>, {fields.Role}
      </Styled.ContactRow>
      <Styled.ContactRow>
        <Styled.Link href={`mailto:${fields.Email}`}>{fields.Email}</Styled.Link>
      </Styled.ContactRow>
      <Styled.ContactRow>{fields["Phone number"]}</Styled.ContactRow>
    </Styled.EscalationPoint>
  );
};

export default EscalationPoint;
