import styled from "styled-components";
import { categoryBorder } from "../UpdatesList/styled";
import { AnchorHeading as OriginalAnchorHeading } from "@/components/layout/DocLayout/styled";

export const Update = styled.div``;

export const RecentUpdatesItem = styled.div`
  ${categoryBorder};
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  &:first-of-type {
    margin-top: 0.5rem;
  }
`;

export const Date = styled.span`
  padding-left: 0.5rem;
  color: ${(props) => props.theme.colors.utility};
  font-weight: 300;
  font-size: 0.875rem;
`;

export const AnchorHeading = styled(OriginalAnchorHeading)`
  margin: 0;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  &:first-of-type {
    padding-top: 0;
  }
`;