import { createContext, useContext, useEffect } from "react";
import useApi from "./ApiContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import type { Company } from "@/services/api/types";

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

const CompanyContext = createContext<Company | null>(null);

export const CompanyProvider = ({ children }: React.PropsWithChildren) => {
  const api = useApi();

  const { data: company } = useQuery(["get-company"], () => api.company.get());

  // Push the company name up to GTM for use in analytics
  useEffect(() => {
    if (window.dataLayer && company) {
      window.dataLayer.push({
        event: "getCompany",
        companyName: company.company_name,
      });
    }
  }, [company]);

  if (!company) {
    return <LoadingScreen withoutPage />;
  }
  return (
    <CompanyContext.Provider value={{ ...company }}>
      {children}
    </CompanyContext.Provider>
  );
};

const useCompany = () => {
  const companyState = useContext(CompanyContext);
  if (companyState === null) {
    throw new Error(
      "Called `useCompanyState` outside the scope of CompanyContext.provider"
    );
  }
  return companyState;
};

export default useCompany;
