import ReactDOM from "react-dom";
import * as Styled from "./styled";
import { useEffect, type ReactNode } from "react";
import Button from "../Button";

type ButtonAction = {
  label: string;
  onClick: () => void;
  "data-testid"?: string;
  type?: "button" | "submit";
};

type Props = {
  modalActive: boolean;
  toggleModal: () => void;
  children: ReactNode;
  title?: string;
  actionButton?: ButtonAction;
  cancelButton?: ButtonAction;
  autoWidth?: boolean;
  customWidth?: string;
  disableOutsideClick?: boolean;
};

const Modal = ({
  modalActive,
  toggleModal,
  children,
  title,
  actionButton,
  cancelButton,
  autoWidth,
  customWidth,
  disableOutsideClick,
}: Props) => {
  // Prevents body scroll when the modal is open and restores it when closed.
  useEffect(() => {
    const bodyElt = document.querySelector("body");
    if (bodyElt) {
      modalActive
        ? (bodyElt.style.overflow = "hidden")
        : (bodyElt.style.overflow = "auto");
    }
  }, [modalActive]);

  // Restore body scroll on component unmount just in case (e.g. navigating away via a modal button)
  useEffect(() => {
    const bodyElt = document.querySelector("body");
    if (bodyElt) {
      bodyElt.style.overflow = "auto";
    }
  }, []);

  if (modalActive) {
    return ReactDOM.createPortal(
      <>
        <Styled.Container>
          <Styled.ClickCatcher
            onClick={() => {
              if (!disableOutsideClick) {
                toggleModal();
              }
            }}
          />
          <Styled.Modal autoWidth={autoWidth} customWidth={customWidth}>
            {!disableOutsideClick && (
              <Styled.CloseIcon
                onClick={(e) => {
                  e.stopPropagation();
                  toggleModal();
                }}
              />
            )}
            {title && <Styled.Title>{title}</Styled.Title>}
            {children}
            {(actionButton || cancelButton) && (
              <Styled.ButtonContainer>
                {cancelButton && (
                  <Button
                    type="button"
                    {...cancelButton}
                    variant="secondary"
                    noArrow
                  />
                )}
                {actionButton && (
                  <Button
                    type="button"
                    {...actionButton}
                    label={actionButton.label}
                    onClick={actionButton.onClick}
                  />
                )}
              </Styled.ButtonContainer>
            )}
          </Styled.Modal>
        </Styled.Container>
      </>,
      document.body
    );
  }
  return null;
};

export default Modal;
