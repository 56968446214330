import Page from "@/components/layout/Page";
import Card from "@/components/shared-ui/Card";
import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import type { AirtableTaskRow } from "@/services/api/types";
import Accordion from "@/components/shared-ui/Accordion";
import ContactCard from "@/components/shared-ui/ContactCard";
import * as Styled from "./styled";
import { useState } from "react";
import Toggle from "@/components/shared-ui/Toggle";
import TasksTable from "./TasksTable";

const Tasks = () => {
  const api = useApi();
  const [hideCompletedTasks, setHideCompletedTasks] = useState(false);

  const { data: tasks } = useQuery(
    ["get-airtable-tasks", hideCompletedTasks],
    () => api.airtableTask.getAll(hideCompletedTasks),
    { keepPreviousData: true }
  );

  if (!tasks) {
    return <LoadingScreen />;
  }

  // Group data by workstream field
  const groupedByWorkstream = tasks.rows.reduce(
    (accumulator, currentValue) => {
      (accumulator[currentValue["Workstream"]] =
        accumulator[currentValue["Workstream"]] || []).push(currentValue);
      return accumulator;
    },
    {} as { [key: string]: AirtableTaskRow[] }
  );

  // Remove workstream from columns as we're grouping by it already
  const filteredFieldNames = tasks.fieldNames.filter(
    (item) => item !== "Workstream"
  );

  return (
    <Page title="Onboarding tasks">
      <Styled.Tasks>
        <Card>
          <Styled.FilterWrapper>
            <Toggle
              label="Hide completed tasks:"
              isChecked={hideCompletedTasks}
              handleToggle={() => setHideCompletedTasks((prev) => !prev)}
            />
          </Styled.FilterWrapper>
          {Object.entries(groupedByWorkstream).map((ws, i) => {
            ws;
            return (
              <Accordion
                startOpen={i === 0}
                heading={ws[0]}
                key={ws[0]}
              >
                <TasksTable columns={filteredFieldNames} data={ws[1]} />
              </Accordion>
            );
          })}
        </Card>
        <ContactCard />
      </Styled.Tasks>
    </Page>
  );
};

export default Tasks;
