import { Card } from "@/components/shared-ui/Card/styled";
import styled from "styled-components";

export const UsefulResources = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding-bottom: 1rem;
  ${Card} {
    min-width: 0;
  }
`;

export const YourDoc = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
  padding: 1rem 0;
  &:last-of-type {
    border: none;
    padding-bottom: 0;
  }
  svg {
    width: 2rem;
    height: 1.5rem;
  }
  &:hover {
    strong {
      color: ${(props) => props.theme.colors.accent1};
    }
  }
`;

export const DocDescription = styled.p`
  margin: 0;
`;

export const Thumbnail = styled.img`
  width: 4.7rem;
  object-fit: cover;
`;

export const SharedDocGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding-top: 0.8rem;
  ${(props) => props.theme.media.medium} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const SharedDoc = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 1rem;
  padding: 1rem 0;
  &:hover {
    strong {
      color: ${(props) => props.theme.colors.accent1};
    }
  }
`;
