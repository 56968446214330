import styled from "styled-components";

export const ServiceSummaryContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  font-size: 0.875rem;
  ${(props) => props.theme.media.medium} {
    grid-template-columns: max-content 1fr;
    gap: 2rem;
  }
`;

export const FieldValuesWrapper = styled.div`
  display: flex;
  gap: 0.5rem 1rem;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const FieldValue = styled.span`
  background: ${(props) => props.theme.gradients.light2};
  padding: 0 0.8rem;
  border-radius: 0.25rem;
  display: block;
  justify-self: start;
  ${(props) => props.theme.media.medium} {
    white-space: nowrap;
  }
`;

export const Detail = styled.span`
  font-weight: 500;
`;
