import Button from "../Button";
import * as Styled from "./styled";
import type { ButtonVariant } from "../Button/Button";
import type { ReactNode } from "react";

type Props = {
  image?: string;
  heading: string;
  content: ReactNode | string;
  internalLink: string;
  buttonLabel: string;
  buttonVariant?: ButtonVariant;
  gridColumn?: string;
};

const LeftBorderCard = ({
  image,
  heading,
  content,
  internalLink,
  buttonLabel,
  buttonVariant = "tertiary",
  gridColumn,
}: Props) => {
  if (image) {
    return (
      <Styled.PlatformSetupCard gridColumn={gridColumn}>
        <Styled.CardWithImageContainer>
          <Styled.CardImage src={image} />
          <Styled.CardWithImageContent>
            <Styled.CardHeading>{heading}</Styled.CardHeading>
            <Styled.PlatformSetupCardContent>
              {typeof content === "string" ? <p>{content}</p> : content}
              <Button
                variant={buttonVariant}
                internalLink={internalLink}
                label={buttonLabel}
                noWrap
              />
            </Styled.PlatformSetupCardContent>
          </Styled.CardWithImageContent>
        </Styled.CardWithImageContainer>
      </Styled.PlatformSetupCard>
    );
  }
  return (
    <Styled.PlatformSetupCard gridColumn={gridColumn}>
      <Styled.CardHeading>{heading}</Styled.CardHeading>
      <Styled.PlatformSetupCardContent>
        {typeof content === "string" ? <p>{content}</p> : content}
        <Button
          variant={buttonVariant}
          internalLink={internalLink}
          label={buttonLabel}
          noWrap
        />
      </Styled.PlatformSetupCardContent>
    </Styled.PlatformSetupCard>
  );
};

export default LeftBorderCard;
