import NotFound from "@/pages/NotFound";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  component: () => React.ReactNode;
  roleRequired: string;
  roleDenied?: string;
};

const RoleGuard = ({ component, roleRequired, roleDenied }: Props) => {
  const { user } = useAuth0();

  if (
    user &&
    roleDenied &&
    user["https://customer.seccl.tech/roles"].includes(roleDenied)
  ) {
    return <NotFound />;
  } else if (
    roleRequired === "basic" ||
    (user && user["https://customer.seccl.tech/roles"].includes(roleRequired))
  ) {
    return <>{component()}</>;
  } else {
    return <NotFound />;
  }
};

export default RoleGuard;
