import styled from "styled-components";

export const ResponseStatus = styled.div<{ status: "success" | "error" }>`
  text-align: left;
  padding-top: 0.5rem;
  color: ${(props) =>
    props.status === "success"
      ? props.theme.colors.highlight3
      : props.theme.colors.error};
`;
