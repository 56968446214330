import styled from "styled-components";
import svgBackground from "@assets/roadmap-graphic.svg";

export const Introduction = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  background: ${(props) => props.theme.gradients.light2};
  border-radius: 2px;
  padding: 1.5rem;

  ${(props) => props.theme.media.medium} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
  }
`;

export const IntroSection = styled.div<{ hasBackground?: boolean }>`
  p {
    margin-top: 0;
    font-weight: 300;
    font-size: 0.938rem;
    line-height: 1.4rem;
  }

  ${(props) =>
    props.hasBackground &&
    `
    background-image: url(${svgBackground});
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 220px;
  `};

  ${(props) => props.theme.media.medium} {
    grid-column: span 4;
  }
`;

export const Heading = styled.h2`
  margin: 0px auto 0.35rem;

  ${(props) => props.theme.media.medium} {
    font-size: 2rem;
  }
`;

export const SubHeading = styled.h3`
  margin: 0;
  color: ${(props) => props.theme.colors.accent1};

  ${(props) => props.theme.media.medium} {
    font-size: 1.5rem;
  }
`;

export const IconKeyRow = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin-bottom: 1rem;

  p {
    font-weight: 500;
    margin-bottom: 0;
  }
`;

export const IconContainer = styled.div`
  width: 2rem;
`;

export const IconKey = styled.div`
  background-color: ${(props) => props.theme.colors.lightest};
  padding: 1.5rem 1rem;
  border-radius: 2px;

  ${IconKeyRow}:last-of-type {
    margin-bottom: 0;
  }
`;

export const RoadmapReview = styled.div`
  ${(props) => props.theme.media.medium} {
    grid-column: span 12;
  }
`;
