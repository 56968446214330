import Page from "@/components/layout/Page";
import Button from "@/components/shared-ui/Button";
import Card from "@/components/shared-ui/Card";
import Input from "@/components/shared-ui/Input";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import Modal from "@/components/shared-ui/Modal";
import Select from "@/components/shared-ui/Select";
import useApi from "@/contexts/ApiContext";
import type { Auth0User } from "@/services/api/types";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { CardHeading } from "../AdminDashboard/styled";
import { ButtonWrapper, Form } from "../AdminUserEdit/styled";
import { useNavigate } from "react-router-dom";
import MultiSelectDropdown from "@/components/shared-ui/MultiSelectDropdown";
import { AxiosError } from "axios";
import { sortCompanies } from "../admin.utils";

export type Auth0UserCreation = Pick<
  Auth0User,
  "name" | "email" | "app_metadata"
>;

const AdminUserCreate = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [userCreate, setUserCreate] = useState<Auth0UserCreation>({
    name: "",
    email: "",
    app_metadata: { companyId: "1" },
  });
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { data: companies } = useQuery(["get-companies"], () =>
    api.admin.company.getAll()
  );
  const { data: roles } = useQuery(["get-roles"], () =>
    api.admin.roles.getAll()
  );

  const createUser = useMutation({
    mutationFn: api.admin.user.create,
    onSettled: () => {
      setSubmitted(true);
    },
  });

  if (!companies || !roles) {
    return <LoadingScreen isAdmin />;
  }

  const onSubmit = () => {
    setModalOpen(true);
    return false;
  };

  const companyOptions = sortCompanies(companies);

  const roleOptions = [
    ...roles.map((role) => ({
      value: role.id,
      label: (
        <div>
          <strong>{`${role.name} - `}</strong>
          <small>{role.description}</small>
        </div>
      ),
    })),
    {
      disabled: true,
      value: "",
      label: (
        <div>
          <strong>{`basic - `}</strong>
          <small>Default role assigned to all users. Limited features.</small>
        </div>
      ),
    },
  ];

  const displayError = () => {
    const { error } = createUser;

    if (!(error instanceof AxiosError)) {
      return "Sorry there was an issue creating the user.";
    }

    const errorData = error.response?.data;

    if (errorData === "You may not assign this user to the admin role") {
      return (
        <>
          <p>User created but could not assign roles to user.</p>
          <p>
            Ensure the user has a Seccl email address if trying to assign the
            admin role.
          </p>
        </>
      );
    }

    return errorData || "Sorry there was an issue creating the user.";
  };

  return (
    <Page
      title="User create"
      isAdmin
      customBreadcrumb={{ path: "/admin/users", label: "Back to users" }}
    >
      <Card>
        <CardHeading>Create user</CardHeading>
        <Form>
          <Input
            label="Name"
            id="name"
            name="name"
            type="text"
            value={userCreate.name}
            onChange={(e) =>
              setUserCreate((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            data-testid="userNameField"
          />
          <Input
            label="Email"
            id="email"
            name="email"
            type="email"
            value={userCreate.email}
            onChange={(e) =>
              setUserCreate((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            data-testid="userEmailField"
          />

          <Select
            id="company"
            name="company"
            label="Company"
            value={userCreate.app_metadata.companyId}
            onChange={(e) =>
              setUserCreate((prev) => ({
                ...prev,
                app_metadata: {
                  ...prev.app_metadata,
                  companyId: e.target.value,
                },
              }))
            }
            options={companyOptions}
          />
          <MultiSelectDropdown
            id="roles"
            label="Roles"
            options={roleOptions}
            values={userRoles}
            onValueChange={(v) => setUserRoles(v)}
            customTitleText={`${userRoles.length + 1} active`}
          />
          <ButtonWrapper>
            <Button
              type="button"
              label="Create user"
              disabled={modalOpen}
              data-testid="createUserSubmit"
              onClick={onSubmit}
            />
          </ButtonWrapper>
        </Form>
      </Card>
      <Modal
        modalActive={modalOpen && !submitted}
        toggleModal={() => setModalOpen((prev) => !prev)}
        title="Are you sure?"
        actionButton={{
          label: "Create user",
          onClick: () => {
            createUser.mutate({ user: userCreate, rolesToAdd: userRoles });
          },
          "data-testid": "confirmationModalButton",
        }}
        cancelButton={{ label: "Cancel", onClick: () => setModalOpen(false) }}
      >
        <p>This will create a new user and trigger an invite email.</p>
        Ensure you have checked that everything is correct before submitting
        (especially company).
      </Modal>
      <Modal
        modalActive={modalOpen && submitted}
        toggleModal={() => setModalOpen((prev) => !prev)}
        title={createUser.isSuccess ? "Successfully created" : "Error"}
        cancelButton={{
          label: "Close",
          onClick: () => {
            setModalOpen(false);
            setSubmitted(false);
            if (createUser.isSuccess) {
              navigate("/admin/users");
            }
          },
          "data-testid": "completionModalButton",
        }}
        disableOutsideClick={createUser.isSuccess}
      >
        {createUser.isSuccess && "The user has been successfully created."}
        {createUser.isError && displayError()}
      </Modal>
    </Page>
  );
};

export default AdminUserCreate;
