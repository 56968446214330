import styled from "styled-components";
import { ReactComponent as Cross } from "@assets/icons/cross-thin.svg";

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 299;
  background-color: rgba(29, 23, 57, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClickCatcher = styled.div`
  position: absolute;
  z-index: 300;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Modal = styled.div<{ autoWidth?: boolean; customWidth?: string }>`
  background: ${(props) => props.theme.colors.lightest};
  margin: 1rem;
  overflow: auto;
  z-index: 301;
  width: ${(props) => (props.customWidth ? props.customWidth : "500px")};
  text-align: center;
  padding: 1.875rem;
  max-height: 90%;
  position: relative;
  border-radius: 0.5rem;
  box-shadow: ${(props) => props.theme.shadows.medium};
  ${(props) =>
    props.autoWidth &&
    `
      width: auto;
      max-width: 90%;
`}
`;

export const CloseIcon = styled(Cross)`
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  cursor: pointer;
  width: 1rem;
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
  justify-content: center;
  padding-top: 2rem;
`;

export const Title = styled.h2`
  margin-top: 0;
`;
