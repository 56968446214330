import styled from "styled-components";
import type { ButtonVariant } from "./Button";

export const Button = styled.button<{
  variant?: ButtonVariant;
  inverse?: boolean;
  centered?: boolean;
  noWrap?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: max-content;
  font-weight: 400;
  color: ${(props) =>
    props.inverse ? props.theme.colors.dark : props.theme.colors.lightest};
  background: ${(props) =>
    props.inverse ? props.theme.colors.lightest : props.theme.colors.dark};
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1.25rem;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.38rem;
  margin: ${(props) => (props.centered ? "auto" : "0")};
  svg {
    width: 0.8rem;
    color: ${(props) => props.theme.colors.highlight2};
    margin-left: 0.8rem;
  }

  &:hover {
    color: ${(props) => props.theme.colors.dark};
    background: ${(props) => props.theme.colors.accent1};
    text-decoration: none;
    box-shadow: none;
    svg {
      color: ${(props) => props.theme.colors.lightest};
    }
  }

  ${(props) =>
    props.variant === "secondary" &&
    `
  background: ${props.inverse ? "transparent" : props.theme.colors.lightest};
  color: ${
    props.inverse ? props.theme.colors.lightest : props.theme.colors.dark
  };
  box-shadow: inset 0 0 0 2px;
  svg {
    color: ${props.theme.colors.highlight2};
  }
  &:hover {
    color: ${props.theme.colors.dark};
    background: ${props.theme.colors.accent1};
    text-decoration: none;
    box-shadow: none;
    svg {
      color: ${props.theme.colors.lightest};
    }
  `}

  ${(props) =>
    props.variant === "tertiary" &&
    `
  background: transparent;
  color: ${
    props.inverse ? props.theme.colors.lightest : props.theme.colors.dark
  };
  padding-left: 0;
  svg {
    color: ${props.theme.colors.highlight2};
  }
  &:hover {
    background: transparent;
    text-decoration: underline;
    text-decoration-color: ${props.theme.colors.accent1};
    svg {
      color: ${props.theme.colors.accent1};
    }
  }
  `}

  ${(props) =>
    props.noWrap &&
    `
    text-wrap: nowrap;
  `}
`;
