import Page from "@/components/layout/Page";
import Button from "@/components/shared-ui/Button";
import FlexContainer from "@components/shared-ui/FlexContainer";

const NotFound = () => {
  return (
    <Page title="404 Not Found">
      <FlexContainer display="flex" flexDirection="column" alignItems="center">
        <h1>{"Oops, we can't find that page..."}</h1>
        <Button label="Go back" internalLink="/" variant="tertiary" />
      </FlexContainer>
    </Page>
  );
};

export default NotFound;
