import Button from "@/components/shared-ui/Button";
import Input from "@/components/shared-ui/Input";
import Modal from "@/components/shared-ui/Modal";
import useApi from "@/contexts/ApiContext";
import useCompany from "@/contexts/CompanyContext";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import * as Styled from "./styled";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  modalActive: boolean;
  toggleModal: () => void;
};

const EditProfile = ({ modalActive, toggleModal }: Props) => {
  const { refresh, ...api } = useApi();
  const { user } = useAuth0();
  const { company_name } = useCompany();
  const [name, setName] = useState<string>("");

  const changePassword = useMutation({
    mutationFn: api.user.changePassword,
  });

  const updateUserName = useMutation({
    mutationFn: api.user.updateUserName,
    onSuccess: () => {
      // Refresh user token via ApiContext to pull updated data through.
      refresh();
    },
  });

  useEffect(() => {
    user && setName(user.name as string);
  }, [user, setName]);

  if (!user) {
    return null;
  }

  return (
    <Modal
      modalActive={modalActive}
      toggleModal={toggleModal}
      title="Edit profile"
      actionButton={{
        label: "Save updates",
        onClick: () => {
          updateUserName.mutate(name);
        },
      }}
      cancelButton={{ label: "Cancel", onClick: () => toggleModal() }}
    >
      <Input
        label="Name"
        id="name"
        name="name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        label="Company"
        id="company"
        name="company"
        type="text"
        disabled
        value={company_name}
      />
      <Input
        label="Work email"
        id="email"
        name="email"
        type="email"
        disabled
        value={user.email}
      />

      <Button
        variant="tertiary"
        label="Click here to change your password"
        onClick={() => changePassword.mutate()}
        disabled={changePassword.isLoading}
      />
      {(changePassword.isSuccess || changePassword.isError) && (
        <Styled.ResponseStatus
          status={changePassword.isSuccess ? "success" : "error"}
        >
          {changePassword.isSuccess
            ? "You should recieve a password reset email shortly."
            : "Sorry, something went wrong"}
        </Styled.ResponseStatus>
      )}
      {(updateUserName.isSuccess || updateUserName.isError) && (
        <Styled.ResponseStatus
          status={updateUserName.isSuccess ? "success" : "error"}
        >
          {updateUserName.isSuccess
            ? "Successfully updated"
            : "Sorry, something went wrong"}
        </Styled.ResponseStatus>
      )}
    </Modal>
  );
};

export default EditProfile;
