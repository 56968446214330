import TagManager from "react-gtm-module";
import AppProviders from "./helpers/AppProviders";
import AuthGuard from "./components/utility/AuthGuard";
import Routing from "./helpers/Routing";
import { useEffect } from "react";
import config from "./config";

const App = () => {
  useEffect(() => {
    if (config.NETLIFY_CONTEXT === "production") {
      TagManager.initialize({
        gtmId: "GTM-KWCSJH89",
      });
    }
  }, []);

  return (
    <AppProviders>
      <AuthGuard component={Routing} />
    </AppProviders>
  );
};

export default App;
