import Blocks, {
  ListBlock,
  type DataProp,
} from "editorjs-blocks-react-renderer";
import ReactPlayer from "react-player/youtube";
import * as Styled from "./styled";
import type { DocumentationContentSection } from "@/services/api/types";
import ImageWithModal from "../ImageWithModal";
import customBlocks from "./customBlocks";
import Button from "../Button";
import config from "@/config";
import FlexContainer from "../FlexContainer";

type Props = {
  blocks: DocumentationContentSection["content"];
};

const BlockRenderer = ({ blocks }: Props) => {
  return (
    <>
      {blocks.map((block) => {
        if (block.collection === "text_block") {
          return <CustomBlocks key={block.id} data={block.item.text_content} />;
        }
        if (block.collection === "diagram_block") {
          return block.item.can_open_in_modal ? (
            <ImageWithModal
              key={block.id}
              image={block.item.image}
              caption={block.item.caption}
              centered={block.item.center_aligned}
            />
          ) : (
            <Styled.Diagram
              key={block.id}
              src={`${config.DIRECTUS_URL}/assets/${block.item.image}`}
              centered={block.item.center_aligned}
            />
          );
        }
        if (block.collection === "card_block") {
          return (
            <Styled.CardWrapper key={block.id}>
              {block.item.cards.map((card) => (
                <Styled.Card key={card.heading} variant={block.item.variant}>
                  <h4>{card.heading}</h4>
                  <CustomBlocks key={block.id} data={card.text} />
                  {card.link_label &&
                    card.external_link_path &&
                    !card.internal_link_path && (
                      <a href={card.external_link_path}>
                        <Button variant="tertiary" label={card.link_label} />
                      </a>
                    )}
                  {card.link_label &&
                    card.internal_link_path &&
                    !card.external_link_path && (
                      <a href={card.internal_link_path}>
                        <Button
                          variant="tertiary"
                          label={card.link_label}
                          internalLink={card.internal_link_path}
                        />
                      </a>
                    )}
                </Styled.Card>
              ))}
            </Styled.CardWrapper>
          );
        }
        if (block.collection === "video_block") {
          return (
            <FlexContainer padding="1rem 0" maxWidth={600} key={block.id}>
              <Styled.VideoWrapper>
                <ReactPlayer
                  url={block.item.youtube_url}
                  className="react-player"
                  width="100%"
                  height="100%"
                  controls={true}
                  config={{ playerVars: { color: "white" } }}
                />
              </Styled.VideoWrapper>
            </FlexContainer>
          );
        }
      })}
    </>
  );
};

const CustomBlocks = ({ data }: { data: DataProp }) => (
  <Blocks
    data={data}
    renderers={{
      nestedlist: ListBlock,
      header: customBlocks.CustomHeader,
      image: customBlocks.CustomImage,
    }}
  />
);

export default BlockRenderer;
