import React from "react";
import * as Styled from "./styled";

type Fields = {
  label: string;
  value: string | string[];
}

type Props = {
  fields: Fields[]
}

const ServiceSummary = ({ fields }: Props) => {

  const featuredFields = [
    "Name",
    "Firm ID",
    "FCA number",
    "Reg status",
    "Distribution",
    "id",
  ];
  const regularFields = fields.filter(
    (item) => !featuredFields.includes(item.label)
  );

  return (
    <Styled.ServiceSummaryContent>
      {regularFields.map((item) => (
        <React.Fragment key={item.label}>
          <Styled.Detail>{item.label}:</Styled.Detail>
          {Array.isArray(item.value) ? (
            <Styled.FieldValuesWrapper>
              {item.value.map((v) => (
                <Styled.FieldValue key={v}>{v}</Styled.FieldValue>
              ))}
            </Styled.FieldValuesWrapper>
          ) : (
            <Styled.FieldValue>{item.value}</Styled.FieldValue>
          )}
        </React.Fragment>
      ))}
    </Styled.ServiceSummaryContent>
  );
};

export default ServiceSummary;