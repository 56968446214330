import { useQuery } from "@tanstack/react-query";
import * as Styled from "./styled";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import useApi from "@/contexts/ApiContext";
import type { AirtableSLARow } from "@/services/api/types";
import SlaTable from "./SLATable";

const ServiceTargets = () => {
  const api = useApi();

  const { data: slaData } = useQuery(
    ["get-airtable-slas"],
    () => api.airtableSLAs.getAll(),
    { keepPreviousData: true }
  );

  if (!slaData) {
    return <LoadingScreen withoutPage />;
  }

  // Remove Area from columns as we're grouping by it already
  const filteredFieldNames = slaData.fieldNames.filter(
    (item) => item !== "Area"
  );

  // Group data by Area field
  const groupedByArea = slaData.rows.reduce(
    (accumulator, currentValue) => {
      (accumulator[currentValue["Area"]] =
        accumulator[currentValue["Area"]] || []).push(currentValue);
      return accumulator;
    },
    {} as { [key: string]: AirtableSLARow[] }
  );

  // Sort keys alphabetically and create a new object
  const sortedGroupedByArea = Object.keys(groupedByArea)
    .sort()
    .reduce(
      (sortedAccumulator, key) => {
        sortedAccumulator[key] = groupedByArea[key];
        return sortedAccumulator;
      },
      {} as { [key: string]: AirtableSLARow[] }
    );

  return (
    <Styled.ServiceTargetsContent>
      <h3>Performance against service targets</h3>
      {!slaData.rows.length ? (
        <p>No data available</p>
      ) : (
        <Styled.TableWrapper>
          {Object.entries(sortedGroupedByArea).map((ws) => {
            return (
              <div key={ws[0]}>
                <Styled.AreaRow>
                  <h4>{ws[0]}</h4>
                </Styled.AreaRow>
                <Styled.AreaTable isSuccessArea={ws[0] === "Customer success"}>
                  <SlaTable columns={filteredFieldNames} data={ws[1]} />
                </Styled.AreaTable>
              </div>
            );
          })}
        </Styled.TableWrapper>
      )}
    </Styled.ServiceTargetsContent>
  );
};

export default ServiceTargets;
