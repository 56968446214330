import { Card } from "@/components/shared-ui/Card/styled";
import styled from "styled-components";

export const Dashboard = styled.div`
  display: grid;
  gap: 1.875rem;
  grid-template-columns: 1fr;
  padding-bottom: 1rem;
  ${(props) => props.theme.media.medium} {
    grid-template-columns: 1fr 1fr;
  }
  ${Card} {
    min-width: 0;
  }
`;

export const CardHeading = styled.h2`
  margin: 0;
`;

export const TasksCard = styled(Card)`
  grid-column: 1 / -1;
`;

export const CardContent = styled.div`
  flex: 1;
`;
