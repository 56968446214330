import useDocsActiveHeading from "@/contexts/DocsActiveHeadingContext";
import { useEffect, useRef } from "react";

type Props = {
  heading: string;
  anchorSuitableId: string;
};

/** 
 * H3 Heading for documentation anchors.
 * Uses intersection observer with useDocsActiveHeading to set active
 * state when the heading scrolls into view.
 */
const AnchorHeading = ({ heading, anchorSuitableId }: Props) => {
  const { setId } = useDocsActiveHeading();
  const element = useRef(null);

  useEffect(() => {
    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setId(entry.target.id);
        }
      });
    };
    const observer = new IntersectionObserver(handleObserver, {
      // Uses minimal window of viewport to avoid multiple headings
      // trying to appear as active at same time.
      rootMargin: "-20% 0px -40% 0px",
      threshold: 1,
    });

    if (element.current) {
      observer.observe(element.current);
    }

    return () => observer.disconnect();
  }, [setId]);

  return (
    <h3 id={anchorSuitableId} ref={element}>
      {heading}
    </h3>
  );
};

export default AnchorHeading;
