import { useCallback, useLayoutEffect, useRef } from "react";

const useClickOutside = (action: (...args: Array<never>) => void) => {
  const element = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const elRef = element.current;
      const target = e.target as HTMLElement;
      if (!elRef?.contains(target)) {
        action();
      }
    },
    [element, action]
  );

  useLayoutEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, [handleClickOutside]);

  return [element];
};

export default useClickOutside;
