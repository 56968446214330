import styled from "styled-components";

export const Input = styled.input<{ $alt?: boolean }>`
  font-size: 1rem;
  line-height: 1.375rem;
  color: inherit;
  background: ${(props) =>
    props.$alt ? props.theme.colors.lightest : props.theme.colors.light};
  border-radius: 0.25rem;
  border: none;
  box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.dark};
  padding: 0.5rem 0.938rem;
  margin: 0;
  outline-color: fade-out(${(props) => props.theme.colors.highlight2}, 0.5);
  transition:
    box-shadow 0.2s,
    outline 0.2s;
  width: 100%;
  &:focus {
    box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.highlight2};
    background: #f2fafd;
  }
  &:disabled {
    color: rgba(29, 23, 57, 0.5);
    box-shadow: none;
  }
`;

export const Wrapper = styled.div<{ $hasLabel?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: ${(props) => (props.$hasLabel ? "1rem" : "0")};
`;

export const Label = styled.label`
  font-weight: 700;
  padding-bottom: 0.3rem;
`;
