import styled from "styled-components";

export const TableFade = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 1rem;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 1) 100%
    );
    width: 100%;
    height: 100%;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
