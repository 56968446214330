import { createContext, useContext, useState } from "react";

type DocsActiveHeadingContextType = {
  activeId: string | null;
  setId: (id: string) => void;
};

const DocsActiveHeadingContext =
  createContext<DocsActiveHeadingContextType | null>(null);

export const DocsActiveHeadingProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const setId = (id: string) => {
    setActiveId(id);
  };
  return (
    <DocsActiveHeadingContext.Provider
      value={{ activeId: activeId, setId: setId }}
    >
      {children}
    </DocsActiveHeadingContext.Provider>
  );
};

const useDocsActiveHeading = () => {
  const activeHeading = useContext(DocsActiveHeadingContext);
  if (activeHeading === null) {
    throw new Error(
      "Called `useDocsActiveHeading` outside the scope of DocsActiveHeadingContext.provider"
    );
  }
  return activeHeading;
};

export default useDocsActiveHeading;
