import useApi from "@/contexts/ApiContext";
import { ReactComponent as PdfIcon } from "@assets/icons/pdf-icon.svg";
import { ReactComponent as PptIcon } from "@assets/icons/ppt-icon.svg";
import { ReactComponent as XlsIcon } from "@assets/icons/xls-icon.svg";
import { ReactComponent as DocIcon } from "@assets/icons/doc-icon.svg";
import { ReactComponent as PlainDocIcon } from "@assets/icons/plain-doc-icon.svg";
import { ReactComponent as FolderIcon } from "@assets/icons/folder-icon.svg";
import * as Styled from "./styled";
import type { DirectusFile, SharepointFileType } from "@/services/api/types";
import Button from "@/components/shared-ui/Button";

type Props = {
  fileObject?: DirectusFile;
  fileLink?: { link: string; type: SharepointFileType };
  buttonLabel?: string;
  /** If this is true a before element will be added to make the clickable area include the parent (provided the parent has position: relative) */
  makeParentClickable?: boolean;
};

const FileDownloadButton = ({
  fileObject,
  fileLink,
  buttonLabel,
  makeParentClickable,
}: Props) => {
  const api = useApi();
  const downloadFile = async (fileId: string, filename: string) => {
    const res = await api.utility.downloadAsset(fileId);
    const url = URL.createObjectURL(await res.data);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderFileIcon = (fileType: string) => {
    switch (fileType) {
      case "application/pdf":
      case "pdf":
        return <PdfIcon />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.oasis.opendocument.text":
      case "word":
        return <DocIcon />;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.oasis.opendocument.spreadsheet":
      case "excel":
        return <XlsIcon />;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.oasis.opendocument.presentation":
      case "powerpoint":
        return <PptIcon />;
      case "folder":
        return <FolderIcon />;
      default:
        return <PlainDocIcon />;
    }
  };

  if (buttonLabel && fileObject) {
    return (
      <Styled.FileDownloadTextButton
        variant="tertiary"
        label={buttonLabel}
        onClick={(e) => {
          e.preventDefault();
          downloadFile(fileObject.id, fileObject.filename_download);
        }}
        makeParentClickable={makeParentClickable}
      />
    );
  }
  if (buttonLabel && fileLink) {
    return (
      <Styled.FileDownloadLink
        href={fileLink.link}
        target="_blank"
        rel="noreferrer"
        makeParentClickable={makeParentClickable}
      >
        <Button variant="tertiary" label={buttonLabel} />
      </Styled.FileDownloadLink>
    );
  }

  if (fileLink) {
    return (
      <Styled.FileDownloadLink
        href={fileLink.link}
        target="_blank"
        rel="noreferrer"
        makeParentClickable={makeParentClickable}
      >
        <Styled.FileDownloadButton as="div">
          {renderFileIcon(fileLink.type)}
        </Styled.FileDownloadButton>
      </Styled.FileDownloadLink>
    );
  }
  if (fileObject) {
    return (
      <Styled.FileDownloadButton
        type="button"
        onClick={(e) => {
          e.preventDefault();
          downloadFile(fileObject.id, fileObject.filename_download);
        }}
        makeParentClickable={makeParentClickable}
      >
        {renderFileIcon(fileObject.type)}
      </Styled.FileDownloadButton>
    );
  }
  return null;
};

export default FileDownloadButton;
