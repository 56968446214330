import * as Styled from "./styled";

type Props = {
  productArea: string;
};

const RoadmapHeader = ({ productArea }: Props) => {
  return (
    <Styled.RoadmapHeader noBorder header>
      <Styled.RoadmapProductHeader>{productArea}</Styled.RoadmapProductHeader>
      <Styled.RoadmapCardHeader>Now</Styled.RoadmapCardHeader>
      <Styled.RoadmapCardHeader>Next</Styled.RoadmapCardHeader>
      <Styled.RoadmapCardHeader>Later</Styled.RoadmapCardHeader>
      <Styled.RoadmapDoneHeader>Done</Styled.RoadmapDoneHeader>
    </Styled.RoadmapHeader>
  );
};

export default RoadmapHeader;
