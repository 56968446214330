import { Card } from "@/components/shared-ui/Card/styled";
import styled from "styled-components";

export const PlatformManagement = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding-bottom: 1rem;
  ${Card} {
    min-width: 0;
  }
`;

export const PageHeading = styled.h1`
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 1.8rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const SectionHeading = styled.h2`
  font-weight: bold;
  font-size: 1rem;
`;

export const CompanySummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  div {
    flex-grow: 1;
  }
  ${(props) => props.theme.media.medium} {
    flex-wrap: wrap;
  }
`;

export const Logo = styled.img`
  height: 40px;
  width: auto;
  display: block;
  margin: 0 auto;
`;

export const CompanySummaryCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: center;
  ${(props) => props.theme.media.medium} {
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Detail = styled.span`
  font-weight: 500;
`;
