import { categoryBorder } from "@/pages/UpdatesList/styled";
import type { Update } from "@/services/api/types";
import styled from "styled-components";

export const Fade = styled.div`
  width: 100%;
  overflow-x: auto;
  padding-top: 1rem;
  position: relative;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 1) 100%
    );
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const UpdateItem = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 0.5rem;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem;
`;

export const UpdateTitle = styled.strong<{
  $category: Update["category"];
}>`
  padding-left: 0.5rem;
  ${categoryBorder};
`;

export const Badge = styled.div`
  background: ${(props) => props.theme.colors.light};
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  width: max-content;
  font-size: 0.875rem;
  font-weight: 300;
  display: inline;
`;
