import { Card } from "@/components/shared-ui/Card/styled";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SystemTraining = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.8rem;
  padding-bottom: 1rem;
  align-items: start;
  ${Card} {
    min-width: 0;
  }
  ${(props) => props.theme.media.medium} {
    grid-template-columns: 250px 1fr;
  }
  ${(props) => props.theme.media.large} {
    grid-template-columns: 325px 1fr;
  }
`;

export const SidebarCard = styled(Card)`
  grid-column: 1;
  grid-row: 2;
  align-self: stretch;
  ${(props) => props.theme.media.medium} {
    grid-row: 1;
  }
`;

export const VideoCard = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.5rem;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const Title = styled.h2`
  margin: 0;
`;

export const SidebarHeading = styled.h2`
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  font-weight: 300;
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
  }
`;

export const SidebarHeadingLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
  svg {
    width: 0.7rem;
    margin-right: 0.7rem;
  }
`;

export const SidebarSubSection = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const SidebarItem = styled(Link)<{ $active?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 0;
  padding-left: 1.2rem;
  ${(props) =>
    props.$active &&
    `
    font-weight: normal;
    `};
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
  }
`;

export const Duration = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${(props) => props.theme.colors.utility};
  font-size: 0.875rem;
  font-weight: 300;
`;

export const SidebarSection = styled.div<{ $active?: boolean }>`
  width: 100%;
  ${SidebarHeading} {
    ${(props) =>
      props.$active &&
      `
      font-weight: bold;
      `};
  }
  ${SidebarSubSection} {
    display: ${(props) => (props.$active ? "block" : "none")};
  }
  svg {
    ${(props) =>
      !props.$active &&
      `
      transform: rotate(270deg);
      `};
  }
  &:first-of-type {
    padding-top: 1rem;
  }
`;
