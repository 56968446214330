import Table from "@/components/shared-ui/Table";
import { createColumnHelper } from "@tanstack/react-table";

type AdminUser = {
  Name: string;
  Email: string;
  Company?: string;
  Actions: JSX.Element;
};

type Props = {
  data: AdminUser[];
};

const AdminUserTable = ({ data }: Props) => {
  const columnHelper = createColumnHelper<AdminUser>();

  const defaultColumns = Object.keys(data[0]).map((col) => {
    return columnHelper.accessor(col as keyof AdminUser, {
      header: () => col,
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    });
  });

  return <Table columns={defaultColumns} data={data} />;
};

export default AdminUserTable;
