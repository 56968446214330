import styled from "styled-components";

export const ErrorScreen = styled.div`
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ErrorMessage = styled(ErrorScreen)`
  margin-top: 1rem;
`;

export const TopLevelErrorMessage = styled.strong`
  margin-top: 2rem;
`;
