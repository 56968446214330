import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import Card from "@/components/shared-ui/Card";
import Button from "@/components/shared-ui/Button";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import { useQuery } from "@tanstack/react-query";
import useApi from "@/contexts/ApiContext";

const AdminDashboard = () => {
  const api = useApi();
  const { data: users } = useQuery(["get-users"], () =>
    api.admin.user.getAll(1)
  );
  const { data: companies } = useQuery(["get-companies"], () =>
    api.admin.company.getAll()
  );

  if (!users || !companies) {
    return <LoadingScreen isAdmin />;
  }
  return (
    <Page title="Admin dashboard" isAdmin isDashboard>
      <Styled.AdminDashboard>
        <Card
          gridColumn="1 / -1"
          featureBackground={{ full: true, gradient: "light3" }}
        >
          <h1>Admin portal</h1>
          <p>Welcome to the admin section of the customer hub.</p>
        </Card>
        <Card gridColumn="1">
          <Styled.CardHeading>User management</Styled.CardHeading>
          <p>
            Create, edit and delete users of the customer hub.
            <br />
            Number of users currently live on the hub:{" "}
            <strong>{users.total}</strong>
          </p>
          <Button
            variant="tertiary"
            internalLink="/admin/users"
            label="Configure users"
            data-testid="configureUsersButton"
          />
        </Card>

        <Card gridColumn={["1", "2"]}>
          <Styled.CardHeading>Companies</Styled.CardHeading>
          <p>
            Number of companies currently live on the hub:{" "}
            <strong>{companies.length}</strong>
          </p>
        </Card>
      </Styled.AdminDashboard>
    </Page>
  );
};

export default AdminDashboard;
