import { useEffect, useCallback } from "react";

export const useDebounce = (
  effect: () => void,
  dependencies: unknown[],
  delay: number
) => {
  // eslint-disable-next-line
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
};
