import Accordion from "@/components/shared-ui/Accordion";
import * as Styled from "./styled";
import type { ReactNode } from "react";
import { useMediaQuery } from "@/hooks/useMatchMedia";

type Props = {
  primarySidebar: {
    visible?: boolean;
    heading?: string;
    menu: ReactNode;
    accordionHeading: string;
  };
  secondarySidebar: {
    visible?: boolean;
    heading?: string;
    menu: ReactNode;
  };
  docHeading: string;
  docPrefix?: ReactNode;
  docContent: ReactNode;
};

const DocLayout = ({
  primarySidebar,
  secondarySidebar,
  docContent,
  docHeading,
  docPrefix,
}: Props) => {
  const isDesktop = useMediaQuery("medium");

  return (
    <Styled.DocLayout>
      <Styled.ContentWrapper>
        {primarySidebar.visible && (
          <Styled.Sidebar>
            {primarySidebar.heading && <h2>{primarySidebar.heading}</h2>}
            {isDesktop ? (
              primarySidebar.menu
            ) : (
              <Accordion heading={primarySidebar.accordionHeading} small>
                {primarySidebar.menu}
              </Accordion>
            )}
          </Styled.Sidebar>
        )}
        <Styled.DocContent id="doc-content">
          {docPrefix}
          <Styled.DocHeading data-testid="docHeading">
            {docHeading}
          </Styled.DocHeading>
          <Styled.DocInnerContent>{docContent}</Styled.DocInnerContent>
        </Styled.DocContent>
        {secondarySidebar.visible && (
          <Styled.AnchorSidebar>
            {secondarySidebar.heading && (
              <Styled.AnchorHeading>
                {secondarySidebar.heading}
              </Styled.AnchorHeading>
            )}
            {secondarySidebar.menu}
          </Styled.AnchorSidebar>
        )}
      </Styled.ContentWrapper>
    </Styled.DocLayout>
  );
};

export default DocLayout;
