import { Card } from "@/components/shared-ui/Card/styled";
import styled from "styled-components";

export const ItemCard = styled(Card)<{ isLater?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.9rem 0.9rem 0.6rem;
  min-height: 130px;
  font-weight: 500;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgb(29, 23, 57, 0.5);
    cursor: pointer;
  }

  ${(props) =>
    props.isLater &&
    `
    background: ${props.theme.colors.light};
    box-shadow: none;
  `};
`;

export const CardTitleContainer = styled.div<{ isLater?: boolean }>`
  padding: 0.5rem;
  background: ${(props) =>
    props.isLater ? props.theme.colors.lightest : props.theme.gradients.light2};
  min-height: 72px;
  border-radius: 4px;
  font-size: 0.938rem;
`;

export const ItemStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.938rem;
  font-weight: 300;
  margin-top: 0.6rem;
`;

export const DeliveryQuarter = styled.div``;

export const ProgressionIcons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`;
