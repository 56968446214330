import Page from "@/components/layout/Page";
import ContactCard from "@/components/shared-ui/ContactCard";

const Contact = () => {
  return (
    <Page title="Contact">
      <ContactCard />
    </Page>
  );
};

export default Contact;
