import type { ThemeGradients } from "@/types/theme";
import styled from "styled-components";
import { grid, margin } from "styled-system";

export const Card = styled.div<{
  $isLink?: boolean;
  $noPadding?: boolean;
  $noShadow?: boolean;
  $featureBackground?: ThemeGradients;
}>`
  background: ${(props) =>
    props.$featureBackground
      ? props.theme.gradients[props.$featureBackground]
      : props.theme.colors.lightest};
  border-radius: 8px;
  box-shadow: ${(props) =>
    props.$noShadow ? "none" : props.theme.shadows.medium};
  padding: ${(props) => (props.$noPadding ? "0" : "0.9rem")};
  ${margin};
  ${grid};
  font-size: 1rem;
  line-height: 1.438rem;
  font-weight: 300;

  ${({ $isLink }) =>
    $isLink &&
    `
  &:hover {
    box-shadow: 0px 0px 10px 0px rgb(29, 23, 57, 0.3);
  }
  `}
`;

export const InnerContainer = styled.div<{
  $featureBackground?: ThemeGradients;
  $noPadding?: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${(props) => (props.$noPadding ? "0" : "0.9rem")};
  background: ${(props) =>
    props.$featureBackground
      ? props.theme.gradients[props.$featureBackground]
      : "none"};
`;
