import * as Styled from "./styled";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type RowData,
} from "@tanstack/react-table";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    centered?: boolean;
  }
}

type Props = {
  // eslint-disable-next-line
  columns: ColumnDef<any, any>[];
  data: object[];
  fixedLayout?: boolean;
};

const Table = ({ data, columns, fixedLayout = false }: Props) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(), 
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
  });

  return (
    <Styled.Table fixedLayout={fixedLayout}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <Styled.HeaderCell
                  key={header.id}
                  centered={header.column.columnDef.meta?.centered}
                  colWidth={header.getSize()}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </Styled.HeaderCell>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Styled.Table>
  );
};

export default Table;
