import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    _hsq: { push: (callParam: [string, (string | object)?]) => void };
  }
}

const Analytics = () => {
  const { pathname } = useLocation();
  const { user } = useAuth0();

  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", pathname]);
    _hsq.push(["trackPageView"]);
  }, [pathname]);

  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    if (user?.email) {
      _hsq.push([
        "identify",
        {
          email: user.email,
        },
      ]);
    }
  }, [user?.email]);

  return null;
};

export default Analytics;
