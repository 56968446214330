import { css } from "styled-components";

export const linkStyles = css`
  a {
    font-weight: 600;
    color: ${(props) => props.theme.colors.accent2};
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.colors.dark};
      text-decoration: underline;
      text-decoration-color: ${(props) => props.theme.colors.accent1};
    }
  }
`;

export const typography = css`
  h1 {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2rem;
    color: ${(props) => props.theme.colors.dark};
    margin-bottom: 1rem;
    margin-top: 0.5rem;

    ${(props) => props.theme.media.medium} {
      font-size: 2rem;
      line-height: 2.25rem;
    }

    ${(props) => props.theme.media.large} {
      font-size: 2rem;
      line-height: 2.25rem;
    }
  }

  h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.dark};

    ${(props) => props.theme.media.medium} {
      font-size: 2.0625rem;
      line-height: 2.5625rem;
    }

    ${(props) => props.theme.media.large} {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
  }

  h3 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.dark};
  }

  h4 {
    font-size: 1.063rem;
    line-height: 1.188rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.625rem;

    ${(props) => props.theme.media.large} {
      font-size: 1.125rem;
      line-height: 1.25rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 300;
    & + p,
    & + h3 {
      margin-top: 1rem;
    }

    ${(props) => props.theme.media.medium} {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    ${linkStyles};
  }

  strong {
    font-weight: 700;
  }

  blockquote {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${(props) => props.theme.colors.dark};
    border-left: 0.125rem solid ${(props) => props.theme.colors.highlight2};
    padding-left: 2.375rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  cite {
    font-style: normal;
  }

  blockquote p {
    line-height: 1.6875rem;
  }

  ul {
    padding-left: 1.5rem;
    list-style-type: "— ";
  }
  ol {
    padding-left: 1.5rem;
  }
  li {
    padding-left: 1.25rem;
    line-height: 1.75rem;
    &::marker {
      color: ${(props) => props.theme.colors.accent1};
      font-weight: normal;
    }
    & + li {
      margin-top: 1rem;
    }
    & > ul,
    & > ol {
      margin-top: 1rem;
      font-weight: 300;
      li + li {
        margin-top: 1rem;
      }
    }
  }

  small,
  .small {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  time {
    display: block;
    font-weight: 300;
    color: ${(props) => props.theme.colors.dark};
  }

  small time {
    display: inline;
  }

  small,
  th {
    display: block;
  }

  table {
    font-size: 0.875rem;
    line-height: 1.75rem;
    text-align: left;
    border-collapse: collapse;
    margin: 1.188rem 0 0;
    width: 100%;
    th,
    td {
      padding: 0.5rem 1.7rem;
    }
    th {
      display: table-cell;
      font-weight: 600;
      padding-top: 0;
    }
    th:first-of-type,
    td:first-of-type {
      padding-left: 2.5rem;
    }
    th:last-of-type,
    td:last-of-type {
      padding-right: 2.5rem;
    }
    tbody tr:nth-of-type(2n + 1) {
      background: ${(props) => props.theme.gradients.light2};
    }
    th > *:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
`;
