import Page from "@/components/layout/Page";
import * as Styled from "./styled";

const LoadingScreen = ({
  withoutPage,
  isAdmin,
}: {
  withoutPage?: boolean;
  isAdmin?: boolean;
}) => {
  if (withoutPage) {
    return (
      <Styled.LoadingScreen>
        <Styled.Spinner />
      </Styled.LoadingScreen>
    );
  } else {
    return (
      <Page title="..." isAdmin={isAdmin}>
        <Styled.LoadingScreen>
          <Styled.Spinner />
        </Styled.LoadingScreen>
      </Page>
    );
  }
};

export default LoadingScreen;
