import styled from "styled-components";

export const ToggleWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Checkbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const ToggleLabel = styled.label<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background: ${(props) =>
    props.$checked ? props.theme.colors.accent1 : props.theme.colors.utility};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`;

export const ToggleButton = styled.span<{ $checked: boolean }>`
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  ${(props) =>
    props.$checked &&
    `
  left: calc(100% - 3px);
  transform: translateX(-100%);
  `}
`;

export const Label = styled.label`
  cursor: pointer;
  font-size: 0.875rem;
`;
