import * as Styled from "./styled";

type Props = {
  label?: string;
  id: string;
  name: string;
  readOnly?: boolean;
  value?: string | number;
  type: "text" | "password" | "email" | "number";
  altVariant?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  label,
  id,
  name,
  type,
  value,
  altVariant,
  ...props
}: Props) => {
  return (
    <Styled.Wrapper $hasLabel={!!label}>
      {label && <Styled.Label htmlFor={id}>{label}</Styled.Label>}
      <Styled.Input
        {...props}
        id={id}
        name={name}
        type={type}
        value={value}
        $alt={altVariant}
      />
    </Styled.Wrapper>
  );
};

export default Input;
