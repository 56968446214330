import { useAuth0 } from "@auth0/auth0-react";
import * as Styled from "./styled";
import { ReactComponent as Logo } from "@assets/seccl-logo.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Chevron } from "@assets/icons/arrow-down.svg";
import { useState } from "react";
import useClickOutside from "@/hooks/useClickOutside";
import EditProfile from "./EditProfile";
import Button from "@/components/shared-ui/Button";
import Search from "@/components/shared-ui/Search";
import { useMediaQuery } from "@/hooks/useMatchMedia";
import type { SearchIndexTypes } from "@/components/shared-ui/Search/Search";
import useCompany from "@/contexts/CompanyContext";

type Props = {
  isAdmin?: boolean;
  searchIndexPriority?: SearchIndexTypes;
};

const Header = ({ isAdmin, searchIndexPriority }: Props) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const { company_name, firm_id } = useCompany();
  const isDesktop = useMediaQuery("medium");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [element] = useClickOutside(() => setDropdownOpen(false));

  const isAdminUser =
    user && user["https://customer.seccl.tech/roles"].includes("admin");
  const isFullUser =
    user && user["https://customer.seccl.tech/roles"].includes("all_features");

  return (
    <Styled.Header $alt={isAdmin}>
      <Styled.HeaderContent>
        <Link to="/">
          <Logo />
        </Link>
        {isDesktop && !isAdmin ? (
          <Search indexPriority={searchIndexPriority} />
        ) : null}
        <Styled.HeaderUtilitySection>
          {isAuthenticated && user && (
            <Styled.UserWidget
              ref={element}
              onClick={() => setDropdownOpen((prev) => !prev)}
              data-testid="userWidget"
            >
              <strong>{company_name}</strong>
              <Chevron />
              <Styled.Dropdown open={dropdownOpen}>
                <Styled.DropdownSection $withBackground>
                  <Styled.DropdownHeading>Logged in as:</Styled.DropdownHeading>
                  <strong>{user.name}</strong>
                  <Styled.DropdownLightText>
                    {user.email}
                  </Styled.DropdownLightText>
                  <Button
                    label="Edit profile"
                    type="button"
                    variant="tertiary"
                    onClick={() => setModalOpen(true)}
                  />
                </Styled.DropdownSection>
                <Styled.DropdownSection>
                  <Styled.DropdownHeading>Company:</Styled.DropdownHeading>
                  <strong>{company_name}</strong>
                  {firm_id && (
                    <Styled.DropdownLightText>
                      {firm_id}
                    </Styled.DropdownLightText>
                  )}
                </Styled.DropdownSection>
                {isFullUser && firm_id && (
                  <Styled.DropdownItem as={Link} to="/platform-management">
                    Service summary
                  </Styled.DropdownItem>
                )}
                {isAdminUser && (
                  <Styled.DropdownItem
                    as={Link}
                    to={isAdmin ? "/" : "/admin"}
                    data-testid="viewAdminPortalButton"
                  >
                    {isAdmin ? "View customer hub" : "View admin portal"}
                  </Styled.DropdownItem>
                )}
                <Styled.DropdownItem
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Logout
                </Styled.DropdownItem>
              </Styled.Dropdown>
            </Styled.UserWidget>
          )}
          {isDesktop && !isAdmin ? (
            <Button label="Contact us" internalLink="/contact" inverse />
          ) : null}
        </Styled.HeaderUtilitySection>
      </Styled.HeaderContent>
      {isAuthenticated && (
        <EditProfile
          modalActive={modalOpen}
          toggleModal={() => setModalOpen((prev) => !prev)}
        />
      )}
    </Styled.Header>
  );
};

export default Header;
