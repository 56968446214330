import type { FunctionComponent } from "react";
import * as Styled from "./styled";

type Props = {
  menuItems: {
    label: string;
    Icon: FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    onClick: () => void;
  }[];
};

const ActionMenu = ({ menuItems }: Props) => {
  return (
    <Styled.ActionMenu>
      {menuItems.map(({ label, Icon, onClick }) => {
        return <Icon key={label} title={label} onClick={onClick} />;
      })}
    </Styled.ActionMenu>
  );
};

export default ActionMenu;
