import useCompany from "@/contexts/CompanyContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ProductFruits } from "react-product-fruits";

const ProductFruitsSurvey = () => {
  const { user } = useAuth0();
  const { company_name } = useCompany();

  const userInfo = {
    username: user?.email || `${company_name} user`,
    email: user?.email,
    props: { Company: company_name, "Full name": user?.name || "" },
  };

  return user && company_name ? (
    <ProductFruits
      workspaceCode="OigWRxa0gHaVEOxc"
      language="en"
      user={userInfo}
    />
  ) : null;
};

export default ProductFruitsSurvey;
