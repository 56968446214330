import styled, { css } from "styled-components";

export const TabbedDisplay = styled.div`
  padding-top: 1rem;
`;

export const TabSection = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const Tab = styled.div<{ $active: boolean }>`
  cursor: pointer;
  font-weight: 500;
  padding: 0.5rem 0;
  ${(props) =>
    props.$active &&
    css`
      font-weight: bold;
      border-bottom: 3px solid ${(props) => props.theme.colors.accent1};
    `}
`;

export const ContentSection = styled.div`
  width: 100%;
  padding: 1rem 0;
`;
