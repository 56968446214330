import styled from "styled-components";
import {
  Roadmap,
  DoneColumn,
  ProductColumn,
  RoadmapCardColumn,
} from "../styled";

export const RoadmapHeader = styled(Roadmap)``;

export const RoadmapProductHeader = styled(ProductColumn)`
  text-transform: capitalize;
  font-size: 1.125rem;
  font-weight: bold;
`;

export const RoadmapCardHeader = styled(RoadmapCardColumn)`
  color: ${(props) => props.theme.colors.accent1};
  font-size: 1rem;
`;

export const RoadmapDoneHeader = styled(DoneColumn)`
  color: ${(props) => props.theme.colors.accent1};
  font-size: 1rem;
`;
