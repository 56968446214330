import { linkStyles } from "@/styles/typography";
import styled from "styled-components";
import { Card } from "../Card/styled";

export const ContactCard = styled.div`
  ${linkStyles};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  ${Card} {
    flex: 1;
    min-width: 45%;
  }

  ${(props) => props.theme.media.medium} {
    flex-direction: row;
    flex-wrap: wrap;
  }
  overflow: hidden;
`;

export const Email = styled.a`
  word-wrap: break-word;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Thumbnail = styled.img`
  width 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 0.5rem;
`;