import config from "@/config";
import AnchorHeading from "@/pages/Documentation/AnchorHeading";
import { type RenderFn, ImageBlock } from "editorjs-blocks-react-renderer";

type CustomHeaderType = {
  text: string;
  level: number;
};
const CustomHeader: RenderFn<CustomHeaderType> = ({
  data,
}: {
  data: CustomHeaderType;
}) => {
  const Tag = `h${data?.level || 1}` as keyof JSX.IntrinsicElements;
  const anchorSuitableId = data?.text.replace(/\s+/g, "-").toLowerCase();
  if (data.level === 3) {
    return (
      <AnchorHeading anchorSuitableId={anchorSuitableId} heading={data?.text} />
    );
  }
  return <Tag>{data?.text && data.text}</Tag>;
};

interface ImageBlockData {
  file?: {
    url: string;
    name?: string;
  };
  url?: string;
  caption: string;
  withBorder: boolean;
  withBackground: boolean;
  stretched: boolean;
}

interface ImageBlockConfig {
  actionsClassNames?: {
    [s: string]: string;
  };
}

const CustomImage: RenderFn<ImageBlockData, ImageBlockConfig | undefined> = ({
  data,
  ...props
}: {
  data: ImageBlockData;
}) => {
  const updatedUrl = config.DIRECTUS_URL + data.file?.url;
  return (
    <ImageBlock
      {...props}
      data={{ ...data, file: { ...data.file, url: updatedUrl } }}
    />
  );
};

const customBlocks = { CustomImage, CustomHeader };

export default customBlocks;
