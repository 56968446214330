import styled from "styled-components";

export const PageBackground = styled.div<{
  $featureBackground?: boolean;
  $whiteBackground?: boolean;
  $stickyBreadcrumbs?: boolean;
}>`
  background: ${(props) =>
    props.$whiteBackground
      ? props.theme.colors.lightest
      : props.$featureBackground
      ? `linear-gradient(177deg, rgba(255,255,255,1) 35rem, ${props.theme.colors.light} calc(35rem + 1px))`
      : props.theme.colors.light};
  min-height: 100vh;
  padding-top: ${(props) => (props.$stickyBreadcrumbs ? "7.5rem" : "5rem")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Body = styled.div<{ $fullWidth?: boolean; $noPadding?: boolean }>`
  color: ${(props) => props.theme.colors.dark};
  width: calc(100% - 2rem);
  max-width: ${(props) => (props.$fullWidth ? "unset" : "1200px")};
  margin: 0 1rem;
  ${(props) =>
    props.$noPadding &&
    `
  width: 100%;
  margin: 0;
  `}
`;

export const Breadcrumbs = styled.div<{ $stickyBreadcrumbs?: boolean }>`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  ${(props) =>
    props.$stickyBreadcrumbs &&
    `
  position: fixed;
  top: 5rem;
  left: 0;
  background: ${props.theme.colors.light};
  z-index: 5;
  `}
`;

export const BreadcrumbsContent = styled.div<{ $stickyBreadcrumbs?: boolean }>`
  width: calc(100% - 2rem);
  max-width: 1200px;
  margin: 0 1rem;
  padding: 0.7rem 0;
  display: flex;
  align-items: center;
  svg {
    width: 0.8rem;
    margin-right: 0.5rem;
  }
`;
