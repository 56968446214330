import { Link } from "react-router-dom";
import styled from "styled-components";

export const AccordionHeading = styled.div`
  display: flex;
  padding-right: 1rem;
`;

export const AccordionCount = styled.span`
  padding-right: 1.5rem;
  color: ${(props) => props.theme.colors.accent1};
`;

export const DueDiligenceContent = styled.div`
  &:first-child {
    p:first-child {
      margin-top: 0;
    }
  }
`;

export const SidebarCount = styled(AccordionCount)`
  padding-right: 0.5rem;
`;

export const SidebarHeading = styled.h2<{ $active?: boolean }>`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
  font-weight: ${(props) => (props.$active ? "bold" : "300")};
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
  }
`;

export const SidebarHeadingLink = styled(Link)`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
`;