import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import DocLayout from "@/components/layout/DocLayout";
import { useParams } from "react-router-dom";
import BlockRenderer from "@/components/shared-ui/BlockRenderer";
import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import Accordion from "@/components/shared-ui/Accordion";
import FileDownloadButton from "@/components/utility/FileDownloadButton";
import { Download } from "@/components/layout/DocLayout/styled";

const DueDiligence = () => {
  const api = useApi();
  const { ddSlug } = useParams();
  const { data: dd } = useQuery(["get-due-diligence"], () =>
    api.dueDiligence.getAll()
  );
  const { data: ddFile } = useQuery(["get-due-diligence-file"], () =>
    api.dueDiligence.getFile()
  );

  const currentDocIndex = dd?.findIndex((item) => item.slug === ddSlug);
  const currentDoc =
    currentDocIndex !== undefined && dd ? dd[currentDocIndex] : undefined;

  if (!dd || !currentDoc || currentDocIndex === undefined || !ddFile) {
    return <LoadingScreen />;
  }

  const mainMenu = dd.map((item, i) => {
    return (
      <Styled.SidebarHeadingLink
        key={item.id}
        to={`/due-diligence/${item.slug}`}
      >
        <Styled.SidebarCount>{i + 1}.</Styled.SidebarCount>
        <Styled.SidebarHeading $active={currentDoc.id === item.id}>
          {item.title}
        </Styled.SidebarHeading>
      </Styled.SidebarHeadingLink>
    );
  });

  const docContent = (
    <>
      {currentDoc.content_sections.map((section, i) => {
        const heading = (
          <Styled.AccordionHeading>
            <Styled.AccordionCount>{`${currentDocIndex + 1}.${
              i + 1
            }`}</Styled.AccordionCount>
            <span>{section.title}</span>
          </Styled.AccordionHeading>
        );
        return (
          <Accordion
            heading={section.title}
            key={section.id}
            small
            headingComponent={heading}
          >
            <Styled.DueDiligenceContent>
              <BlockRenderer blocks={section.content} />
            </Styled.DueDiligenceContent>
          </Accordion>
        );
      })}
    </>
  );
  return (
    <Page
      title="Due diligence"
      noPadding
      fullWidth
      stickyBreadcrumbs
      searchIndexPriority="dd"
    >
      <DocLayout
        primarySidebar={{
          visible: true,
          heading: "Due diligence",
          menu: mainMenu,
          accordionHeading: currentDoc.title,
        }}
        secondarySidebar={{
          visible: true,
          heading: "Download",
          menu: (
            <Download>
              <FileDownloadButton
                fileObject={ddFile.file}
                makeParentClickable
              />
              Full due diligence document
            </Download>
          ),
        }}
        docHeading={currentDoc.title}
        docContent={docContent}
      />
    </Page>
  );
};

export default DueDiligence;
