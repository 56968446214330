import type { AccountManagementType } from "@/services/api/types";
import * as Styled from "./styled";
import EscalationPoint from "./EscalationPoint";

type Props = {
  fields: AccountManagementType & { Status: string };
};

const AccountManagement = ({ fields }: Props) => {
  return (
    <Styled.ServiceSummaryContent>
      {fields.Status && (
        <>
          <Styled.Detail>Status:</Styled.Detail>
          <Styled.HighlightedFieldValue isStatus>
            {fields.Status}
          </Styled.HighlightedFieldValue>
        </>
      )}
      {fields["Account manager"] && (
        <>
          <Styled.Detail>Account manager:</Styled.Detail>
          <Styled.FieldValuesWrapper>
            {fields["Account manager"].map((v) => (
              <Styled.FieldValue key={v}>{v}</Styled.FieldValue>
            ))}
          </Styled.FieldValuesWrapper>
        </>
      )}
      {fields["Customer success exec"] && (
        <>
          <Styled.Detail>Customer success exec:</Styled.Detail>
          <Styled.FieldValuesWrapper>
            {fields["Customer success exec"].map((v) => (
              <Styled.FieldValue key={v}>{v}</Styled.FieldValue>
            ))}
          </Styled.FieldValuesWrapper>
        </>
      )}
      {fields.escalationPoint && (
        <>
          <Styled.Detail>Escalation point:</Styled.Detail>
          <Styled.FieldValuesWrapper isEscalationPoint>
            {fields.escalationPoint.map((contact) => {
              return <EscalationPoint key={contact.Name} fields={contact} />;
            })}
          </Styled.FieldValuesWrapper>
        </>
      )}
      {fields.Meetings && (
        <>
          <Styled.Detail>Meetings:</Styled.Detail>
          <Styled.FieldValuesWrapper>
            {fields.Meetings.map((v) => (
              <Styled.HighlightedFieldValue key={v}>
                {v}
              </Styled.HighlightedFieldValue>
            ))}
          </Styled.FieldValuesWrapper>
        </>
      )}
    </Styled.ServiceSummaryContent>
  );
};

export default AccountManagement;
