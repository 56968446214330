import { CardHeading } from "@/pages/Dashboard/styled";
import Card from "../Card";
import * as Styled from "./styled";
import useCompany from "@/contexts/CompanyContext";
import Button from "../Button";
import config from "@/config";
import { useMediaQuery } from "@/hooks/useMatchMedia";

const ContactCard = () => {
  const { contacts } = useCompany();
  const isDesktop = useMediaQuery("medium");

  return contacts ? (
    <Card gridColumn="1 / -1">
      <CardHeading>Key contacts</CardHeading>
      <Styled.ContactCard>
        {contacts.map(({ contact_id }) => (
          <Card
            key={contact_id.name}
            featureBackground={{ full: true, gradient: "light2" }}
          >
            <Styled.Wrapper>
              {isDesktop && (
                <Styled.Thumbnail
                  src={`${config.DIRECTUS_URL}/assets/${contact_id.thumbnail}`}
                />
              )}
              <div>
                <strong>{contact_id.role}</strong>
                <div>
                  {contact_id.name}
                  {" - "}
                  <Styled.Email href={`mailto:${contact_id.email}`}>
                    {contact_id.email}
                  </Styled.Email>
                </div>
                {contact_id.phone_number && `Tel: ${contact_id.phone_number}`}
                {contact_id.meeting_link && (
                  <a href={contact_id.meeting_link}>
                    <Button label="Book a meeting" variant="tertiary" />
                  </a>
                )}
              </div>
            </Styled.Wrapper>
          </Card>
        ))}
      </Styled.ContactCard>
    </Card>
  ) : null;
};

export default ContactCard;
