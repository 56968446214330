import React from "react";
import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import Card from "@/components/shared-ui/Card";
import config from "@/config";
import useCompany from "@/contexts/CompanyContext";
import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import TabbedDisplay from "@/components/shared-ui/TabbedDisplay";
import ContactCard from "@/components/shared-ui/ContactCard";
import ServiceSummary from "./ServiceSummary";
import ServiceTargets from "./ServiceTargets";
import AccountManagement from "./AccountManagement";

const PlatformManagement = () => {
  const api = useApi();
  const { logo, company_name } = useCompany();

  const { data: companyConfig } = useQuery(["get-company-config"], () =>
    api.company.getConfig()
  );

  if (!companyConfig) {
    return <LoadingScreen />;
  }

  const { configData, accountManagementData } = companyConfig;

  const fields = Object.entries(configData).map(([k, v]) => ({
    label: k,
    value: v,
  }));

  return (
    <Page title="Platform management">
      <Styled.PlatformManagement>
        <Card>
          <Styled.PageHeading>Platform management</Styled.PageHeading>
          <Styled.SectionHeading>Company summary</Styled.SectionHeading>
          <Styled.CompanySummaryWrapper>
            <Card>
              <Styled.CompanySummaryCard>
                {logo ? (
                  <Styled.Logo src={`${config.DIRECTUS_URL}/assets/${logo}`} />
                ) : (
                  <h2>{company_name}</h2>
                )}
              </Styled.CompanySummaryCard>
            </Card>
            <Card featureBackground={{ full: true, gradient: "light2" }}>
              <Styled.CompanySummaryCard>
                {fields
                  .filter((item) =>
                    ["Name", "Firm ID", "FCA number"].includes(item.label)
                  )
                  .map((field) => (
                    <FeatureField
                      key={field.label}
                      label={field.label}
                      value={field.value}
                    />
                  ))}
              </Styled.CompanySummaryCard>
            </Card>
            <Card featureBackground={{ full: true, gradient: "light2" }}>
              <Styled.CompanySummaryCard>
                {fields
                  .filter((item) =>
                    ["Reg status", "Distribution"].includes(item.label)
                  )
                  .map((field) => (
                    <FeatureField
                      key={field.label}
                      label={field.label}
                      value={field.value}
                    />
                  ))}
              </Styled.CompanySummaryCard>
            </Card>
          </Styled.CompanySummaryWrapper>
          <TabbedDisplay
            tabs={[
              ...(accountManagementData
                ? [
                    {
                      label: "Account management",
                      content: (
                        <AccountManagement
                          fields={{
                            Status: configData.Status,
                            ...accountManagementData,
                          }}
                        />
                      ),
                    },
                  ]
                : []),
              ...(configData.Status === "Live"
                ? [
                    {
                      label: "Service targets",
                      content: <ServiceTargets />,
                    },
                  ]
                : []),
              {
                label: "Service summary",
                content: <ServiceSummary fields={fields} />,
              },
            ]}
          />
        </Card>
        <ContactCard />
      </Styled.PlatformManagement>
    </Page>
  );
};

export default PlatformManagement;

const FeatureField = ({
  label,
  value,
}: {
  label: string;
  value: string | string[];
}) => {
  return (
    <Styled.DetailItem>
      <span>{label}:</span>
      <Styled.Detail>
        {Array.isArray(value)
          ? value.map((v, i) => (
              <React.Fragment key={i}>{`${v}${
                i + 1 != value.length ? ", " : ""
              }`}</React.Fragment>
            ))
          : value}
      </Styled.Detail>
    </Styled.DetailItem>
  );
};
