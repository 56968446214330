import { useState } from "react";
import * as Styled from "./styled";
import Modal from "../Modal";
import config from "@/config";
import { ReactComponent as Plus } from "@assets/icons/zoom-plus-icon.svg";
import { ReactComponent as Enlarge } from "@assets/icons/zoom-icon.svg";

type Props = {
  image: string;
  caption?: string;
  centered?: boolean;
};

const ImageWithModal = ({ image, caption, centered }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <Styled.ImageWithModal centered={centered}>
      <Styled.ImageWrapper onClick={() => setOpen(true)}>
        <Styled.InlineImage src={`${config.DIRECTUS_URL}/assets/${image}`} />
        <Styled.HoverImage>
          <Plus />
        </Styled.HoverImage>
      </Styled.ImageWrapper>
      <Styled.ClickToEnlarge onClick={() => setOpen(true)}>
        <Enlarge /> Click to enlarge
      </Styled.ClickToEnlarge>
      <Modal
        modalActive={open}
        toggleModal={() => setOpen((prev) => !prev)}
        autoWidth
      >
        {caption}
        <Styled.ModalImage src={`${config.DIRECTUS_URL}/assets/${image}`} />
      </Modal>
    </Styled.ImageWithModal>
  );
};

export default ImageWithModal;
