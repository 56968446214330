import { createGlobalStyle } from "styled-components";
import { normalize } from "./normalize";
import { fonts } from "./fonts";

const GlobalStyles = createGlobalStyle`

${normalize}
${fonts}

html {
    box-sizing: border-box;
  }
*, *:before, *:after {
    box-sizing: inherit;
}

html, body {
    margin: 0;
    font-family: "BrownLLWeb", "Segoe UI", Helvetica, Arial, sans-serif;
}

a {
    text-decoration: none;
    color: unset;
}

strong {
    font-weight: 700;
}
`;

export default GlobalStyles;
