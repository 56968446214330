import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import { CardHeading } from "../Dashboard/styled";
import Card from "@/components/shared-ui/Card";
import useCompany from "@/contexts/CompanyContext";
import FileDownloadButton from "@/components/utility/FileDownloadButton";
import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import ContactCard from "@/components/shared-ui/ContactCard";
import config from "@/config";

const UsefulResources = () => {
  const api = useApi();
  const { files, sharepoint_files } = useCompany();

  const { data: sharedDocuments } = useQuery(["get-shared-documents"], () =>
    api.sharedDocuments.getAll()
  );

  if (!sharedDocuments) {
    return <LoadingScreen />;
  }
  return (
    <Page title="Useful resources" searchIndexPriority="resources">
      <Styled.UsefulResources>
        <Card>
          <CardHeading>Useful documents</CardHeading>
          <Styled.SharedDocGrid>
            {sharedDocuments.length < 1 && <p>No documents here yet.</p>}
            {sharedDocuments.map((doc) => (
              <Styled.SharedDoc key={doc.id}>
                <Styled.Thumbnail
                  src={`${config.DIRECTUS_URL}/assets/${doc.thumbnail}`}
                />
                <div>
                  <strong>{doc.file.title}</strong>
                  <Styled.DocDescription>
                    {doc.file.description}
                  </Styled.DocDescription>
                  <FileDownloadButton
                    fileObject={doc.file}
                    buttonLabel="Download document"
                    makeParentClickable
                  />
                </div>
              </Styled.SharedDoc>
            ))}
          </Styled.SharedDocGrid>
        </Card>
        <Card>
          <CardHeading>Your documents</CardHeading>
          <div>
            {!files?.length && !sharepoint_files?.length && (
              <p>No files have been added here yet.</p>
            )}
            {files &&
              files.map((file) => (
                <Styled.YourDoc key={file.id}>
                  <FileDownloadButton
                    fileObject={file.directus_files_id}
                    makeParentClickable
                  />
                  <div>
                    <strong>{file.directus_files_id.title}</strong>
                    {file.directus_files_id.description && (
                      <Styled.DocDescription>
                        {file.directus_files_id.description}
                      </Styled.DocDescription>
                    )}
                  </div>
                </Styled.YourDoc>
              ))}
            {sharepoint_files &&
              sharepoint_files.map((file) => (
                <Styled.YourDoc key={file.title}>
                  <FileDownloadButton
                    fileLink={{
                      link: file.sharepoint_link,
                      type: file.file_type,
                    }}
                    makeParentClickable
                  />
                  <div>
                    <strong>{file.title}</strong>
                    {file.description && (
                      <Styled.DocDescription>
                        {file.description}
                      </Styled.DocDescription>
                    )}
                  </div>
                </Styled.YourDoc>
              ))}
          </div>
        </Card>
        <ContactCard />
      </Styled.UsefulResources>
    </Page>
  );
};

export default UsefulResources;
