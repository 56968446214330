import styled from "styled-components";

export const ImageWithModal = styled.div<{ centered?: boolean }>`
  width: fit-content;
  ${(props) =>
    props.centered &&
    `
  margin-left: auto;
  margin-right: auto;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const InlineImage = styled.img<{ centered?: boolean }>`
  position: relative;
  max-height: 350px;
  max-width: 100%;
  object-fit: cover;
  cursor: pointer;
  display: block;
  ${(props) =>
    props.centered &&
    `
  margin-left: auto;
  margin-right: auto;
  `}
`;

export const HoverImage = styled.div`
  position: absolute;
  cursor: pointer;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  ${ImageWrapper}:hover & {
    display: flex;
  }
`;

export const ClickToEnlarge = styled.div`
  cursor: pointer;
  padding-top: 0.3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  svg {
    height: 20px;
    margin-right: 0.2rem;
  }
`;

export const ModalImage = styled.img`
  width: 100%;
  max-height: 100%
  object-fit: cover;
  padding-top: 0.5rem;

`;
