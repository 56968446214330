import type { ThemeType } from "../types/theme";

const theme: ThemeType = {
  colors: {
    darkest: "black",
    dark: "#1D1739",
    light: "#F7F6F8",
    lightest: "white",
    accent1: "#F85C88",
    accent2: "#0573A9",
    highlight1: "#A144A4",
    highlight2: "#8BD2F5",
    highlight3: "#9CE098",
    error: "#E44545",
    utility: "#A4A2B0",
  },
  gradients: {
    dark1: "linear-gradient(90deg, #1D1739 0%, #452559 100%)",
    dark2: "linear-gradient(90deg, #1D1739 0%, #0E5180 100%)",
    dark3: "linear-gradient(90deg, #0573A9 0%, #61B59F 100%)",
    light1: "linear-gradient(90deg, #F8F4FD 0%, #FEE7ED 100%)",
    light2: "linear-gradient(90deg, #F8F4FD 0%, #EEF8FE 100%)",
    light3: "linear-gradient(90deg, #F2FAF0 0%, #EEF8FE 100%)",
  },
  shadows: {
    medium: "0px 0px 10px 0px rgb(29, 23, 57, 0.3)",
    high: "0px 0px 10px 0px rgb(29, 23, 57, 0.5)",
  },
  breakpoints: ["45rem", "60rem"],
  media: {
    medium: "@media (min-width: 45rem)",
    large: "@media (min-width: 60rem)",
  },
};

export default theme;
