import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import { withAuthenticationRequired } from "@auth0/auth0-react";

type Props = {
  component: React.ComponentType<object>;
};

const AuthGuard = ({ component }: Props) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingScreen withoutPage />,
  });

  return <Component />;
};

export default AuthGuard;
