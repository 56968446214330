import styled from "styled-components";

export const ProductRoadmap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding-bottom: 1rem;
`;

export const RoadmapContainer = styled.div`
  margin-bottom: 2rem;
  overflow-x: auto;
`;

export const Roadmap = styled.div<{ noBorder?: boolean; header?: boolean }>`
  margin: 1.125rem 0;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1.25rem;
  border-bottom: ${(props) =>
    props.noBorder ? "none" : `1px solid ${props.theme.colors.light}`};
  padding-bottom: ${(props) => (props.header ? "none" : "1rem")};
`;

export const ProductColumn = styled.div`
  grid-column: span 1;
  min-width: 5rem;
`;

export const RoadmapCardColumn = styled.div`
  grid-column: span 3;
  min-width: 17rem;
`;

export const DoneColumn = styled.div`
  grid-column: span 2;
  min-width: 11.45rem;
  a {
    color: ${(props) => props.theme.colors.accent2};
    font-size: 0.938rem;
    line-height: 1.2rem;
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

export const ProductRowHeader = styled(ProductColumn)`
  padding-top: 1.563rem;
  font-size: 1rem;
  font-weight: 300;
`;

export const CardButton = styled.button`
  border: none;
  background: none;
  appearance: none;
  display: contents;
  text-align: left;
`;
