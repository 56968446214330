import { Card } from "@/components/shared-ui/Card/styled";
import { typography } from "@/styles/typography";
import styled from "styled-components";

export const CardHeading = styled.h2`
  margin: 0;
`;

export const WelcomeCardOuter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding: 0.9rem;

  ${(props) => props.theme.media.medium} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const WelcomeCardInner = styled.div`
  padding: 0.9rem;
  ${typography};
  strong {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;

export const WelcomeCardImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  img {
    display: none;
  }
  ${(props) => props.theme.media.large} {
    grid-template-columns: 1fr 1fr;
    img {
      display: block;
      width: 100%;
    }
  }
`;

export const UpdatesCard = styled(Card)`
  display: flex;
  flex-direction: column;
  box-shadow: none;
  flex-shrink: 0;
`;

export const AccentHeading = styled.span`
  color: ${(props) => props.theme.colors.accent1};
  font-size: 1.5rem;
  display: block;
  line-height: 1.5;
  ${(props) => props.theme.media.medium} {
    font-size: 1.75rem;
    line-height: 1.5;
  }
`;