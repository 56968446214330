import { useState, type ReactNode } from "react";
import * as Styled from "./styled";

type Props = {
  tabs: { label: string; content: ReactNode }[];
};

const TabbedDisplay = ({ tabs }: Props) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Styled.TabbedDisplay>
      <Styled.TabSection>
        {tabs.map((tab, index) => (
          <Styled.Tab
            key={tab.label}
            $active={currentTab === index}
            onClick={() => setCurrentTab(index)}
          >
            {tab.label}
          </Styled.Tab>
        ))}
      </Styled.TabSection>
      <Styled.ContentSection>
        {tabs.find((_, i) => i === currentTab)?.content}
      </Styled.ContentSection>
    </Styled.TabbedDisplay>
  );
};

export default TabbedDisplay;
