import Button from "@/components/shared-ui/Button";
import styled, { css } from "styled-components";

type Download = {
  makeParentClickable?: boolean;
};

export const BeforeComponent = css`
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const FileDownloadButton = styled.button<Download>`
  padding: 0;
  outline: none;
  background: none;
  height: 2.5rem;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.dark};
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
  }

  ${(props) => props.makeParentClickable && BeforeComponent}
`;

export const FileDownloadTextButton = styled(Button)<Download>`
  ${(props) => props.makeParentClickable && BeforeComponent}
`;

export const FileDownloadLink = styled.a<Download>`
  ${(props) => props.makeParentClickable && BeforeComponent}
`;
