import styled, { css } from "styled-components";

export const Table = styled.table<{ fixedLayout: boolean }>`
  ${(props) =>
    props.fixedLayout &&
    css`
      table-layout: fixed;
    `}

  width: 100%;
  border-collapse: collapse;
  th,
  td {
    font-size: 0.875rem;
    padding: 0.5rem 0;
    padding-right: 1rem;
    :last-of-type {
      padding-right: 0;
    }
  }
  td {
    white-space: pre-line;
    text-align: left;
  }
  tr {
    vertical-align: bottom;
    border-bottom: 1px solid ${(props) => props.theme.colors.light};
    &:last-of-type {
      border-bottom: 0;
    }
  }
  thead tr {
    border-bottom: none;
  }
  .centeredCell {
    text-align: center;
  }
`;

export const HeaderCell = styled.th<{ centered?: boolean; colWidth?: number }>`
  white-space: nowrap;
  text-align: ${(props) => (props.centered ? "center" : "left")};
  width: ${(props) =>
    props.colWidth !== 0 ? `${props.colWidth}px` : "auto"};
`;

export const Checkbox = styled.div<{ $complete: boolean }>`
  width: min-content;
  padding: 0 1rem;
  white-space: nowrap;
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const OwnerIcon = styled.div<{ type: "seccl" | "platformOperator" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: ${(props) => props.theme.colors.lightest};
  background: ${(props) =>
    props.type === "seccl"
      ? props.theme.colors.dark
      : props.theme.colors.accent2};
`;
