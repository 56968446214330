import Table from "@/components/shared-ui/Table";
import type { AirtableSLARow } from "@/services/api/types";
import { createColumnHelper } from "@tanstack/react-table";
import * as Styled from "./styled";
import { useMediaQuery } from "@/hooks/useMatchMedia";

type Props = {
  columns: string[];
  data: AirtableSLARow[];
};

const colours = {
  green: {
    cirle: "#9CE098",
    background: "#EBF9EA",
  },
  orange: {
    cirle: "#E4A643",
    background: "#FCF2E3",
  },
  red: {
    cirle: "#E44545",
    background: "#FDECEC",
  },
};

const SLATable = ({ data, columns }: Props) => {
  const columnHelper = createColumnHelper<AirtableSLARow>();
  const isDesktop = useMediaQuery("large");

  function determineColor(targetValue: string, actualValue: string) {
    if (actualValue === "N/A" || !targetValue.includes("%")) {
      return undefined;
    }
    // Convert the percentage strings to numbers
    const target = parseFloat(targetValue);
    const actual = parseFloat(actualValue);

    // Calculate the actual percentage of the target
    const percentageOfTarget = (actual / target) * 100;

    // Determine the color based on the criteria
    if (percentageOfTarget >= 89.9) {
      return colours.green;
    } else if (percentageOfTarget <= 79.8) {
      return colours.red;
    } else {
      return colours.orange;
    }
  }

  const actualsFormatter = (row: AirtableSLARow, value: string | undefined) => {
    if (!value) {
      return undefined;
    }

    const valueColor = determineColor(row.Target, value);

    return (
      <Styled.SLAColor color={valueColor?.background}>
        {valueColor && <Styled.ProgressCircle color={valueColor.cirle} />}
        <span>{value}</span>
      </Styled.SLAColor>
    );
  };

  const defaultColumns = columns.map((col) => {
    if (col === "Metric") {
      return columnHelper.accessor(col, {
        header: () => col,
        cell: (info) => info.getValue(),
        size: isDesktop ? 0 : 300,
      });
    }
    if (col.includes("Actuals")) {
      return columnHelper.accessor((row) => row[col], {
        id: `${col}`,
        header: () => (
          <span
            style={{
              width: "20px",
              whiteSpace: "break-spaces",
              display: "flex",
            }}
          >
            {col}
          </span>
        ),
        cell: (props) => actualsFormatter(props.row.original, props.getValue()),
        size: isDesktop ? 130 : 100,
      });
    }
    return columnHelper.accessor(col, {
      header: () => (
        <span
          style={{ width: "20px", whiteSpace: "break-spaces", display: "flex" }}
        >
          {col}
        </span>
      ),
      cell: (info) => info.getValue(),
      size: isDesktop ? 130 : 100,
    });
  });

  return <Table columns={defaultColumns} data={data} fixedLayout />;
};

export default SLATable;
