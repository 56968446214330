import Modal from "@/components/shared-ui/Modal";
import * as Styled from "./styled";
import type { RoadmapItem } from "@/services/api/types";
import Button from "@/components/shared-ui/Button";

type Props = {
  item: RoadmapItem;
  modalActive: boolean;
  toggleModal: () => void;
  updateLink: string;
};

const DetailsModal = ({
  item,
  modalActive,
  toggleModal,
  updateLink,
}: Props) => {
  return (
    <Modal
      modalActive={modalActive}
      toggleModal={toggleModal}
      customWidth="900px"
    >
      <Styled.ModalContent>
        <h2>{item.roadmap_item}</h2>
        <pre>{item.roadmap_detail}</pre>
        {item.whats_changed_description && (
          <Styled.WhatsChanged>
            <h4>{item.whats_changed_heading}</h4>
            <pre>{item.whats_changed_description}</pre>
            <Button
              variant="tertiary"
              internalLink={updateLink}
              label={item.whats_changed_link_text || "Find out more"}
            />
          </Styled.WhatsChanged>
        )}
      </Styled.ModalContent>
    </Modal>
  );
};

export default DetailsModal;
