import * as Styled from "./styled";

type Props = {
  label: string;
  id: string;
  name: string;
  value?: string | number;
  options: { label: string; id: string }[];
  lightVariant?: boolean;
  compact?: boolean;
} & React.InputHTMLAttributes<HTMLSelectElement>;

const Select = ({
  label,
  id,
  name,
  options,
  value,
  lightVariant,
  compact,
  ...props
}: Props) => {
  return (
    <Styled.Wrapper $compact={compact}>
      <Styled.Label htmlFor={id} $compact={compact}>
        {label}
      </Styled.Label>
      <Styled.Select
        {...props}
        id={id}
        name={name}
        value={value}
        $alt={lightVariant}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </Styled.Select>
    </Styled.Wrapper>
  );
};

export default Select;
