import Table from "@/components/shared-ui/Table";
import * as Styled from "../../../components/shared-ui/Table/styled";
import { createColumnHelper } from "@tanstack/react-table";
import { ReactComponent as CheckboxChecked } from "@assets/icons/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "@assets/icons/checkbox-unchecked.svg";
import FlexContainer from "@/components/shared-ui/FlexContainer";
import type { AirtableTaskRow } from "@/services/api/types";


type Props = {
  columns: string[];
  data: AirtableTaskRow[];
};

const TasksTable = ({ data, columns }: Props) => {
  const columnHelper = createColumnHelper<AirtableTaskRow>();
  const renderOwnerIcons = (owner: string) => {
    switch (owner) {
      case "Seccl":
        return (
          <Styled.OwnerIcon type="seccl" title="Seccl">
            S
          </Styled.OwnerIcon>
        );
      case "Platform operator":
        return (
          <Styled.OwnerIcon type="platformOperator" title="Platform Operator">
            PO
          </Styled.OwnerIcon>
        );
      case "Seccl | Platform operator":
        return (
          <>
            <Styled.OwnerIcon type="seccl" title="Seccl">
              S
            </Styled.OwnerIcon>
            <Styled.OwnerIcon type="platformOperator" title="Platform Operator">
              PO
            </Styled.OwnerIcon>
          </>
        );
    }
  };

  const defaultColumns = columns.map((col) => {
    if (col === "Task") {
      return columnHelper.accessor(col, {
        header: () => col,
        cell: (info) => (info.getValue() ? info.getValue() : "-"),
        size: 300
      });
    }
    if (col === "Status") {
      return columnHelper.accessor(col, {
        header: () => col,
        cell: (info) => (
          <Styled.Checkbox $complete={!!info.getValue()}>
            {info.getValue() ? <CheckboxChecked /> : <CheckboxUnchecked />}
          </Styled.Checkbox>
        ),
        meta: {
          centered: true,
        },
      });
    }
    if (col === "Owner") {
      return columnHelper.accessor(col, {
        header: () => col,
        cell: (info) => {
          return (
            <FlexContainer display="flex" justifyContent="center">
              {renderOwnerIcons(info.getValue())}
            </FlexContainer>
          );
        },
        meta: {
          centered: true,
        },
      });
    }
    return columnHelper.accessor(col as keyof AirtableTaskRow, {
      header: () => col,
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    });
  });

  return (
    <Table columns={defaultColumns} data={data} />
  );
};

export default TasksTable;