import styled from "styled-components";

export const AdminUserManagement = styled.div`
  padding-bottom: 1rem;
`;

export const TableFilters = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem;
  background: ${(props) => props.theme.gradients.light3};
  ${(props) => props.theme.media.medium} {
    flex-direction: row;
    align-items: center;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

export const PaginationWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr;
  margin-top: 0.5rem;
`;

export const PaginationButton = styled.button<{ $col: number }>`
  grid-column-start: ${(props) => props.$col};
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: max-content;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem ;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.38rem;
  background: ${(props) => props.theme.colors.dark};
  svg {
    width: 0.8rem;
    color: ${(props) => props.theme.colors.lightest};
  }
  &:hover {
    color: ${(props) => props.theme.colors.dark};
    background: ${(props) => props.theme.colors.accent1};
    text-decoration: none;
    box-shadow: none;
    svg {
      color: ${(props) => props.theme.colors.lightest};
    }
`;

export const NoResults = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 1rem;
`;
