import styled from "styled-components";

export const ModalContent = styled.div`
  padding: 0.75rem 0.75rem .5rem;
  text-align: left;

  pre {
    font-family: inherit;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 300;
    white-space: pre-wrap;
  }
`;

export const WhatsChanged = styled.div`
  background: ${(props) => props.theme.gradients.light2};
  border-radius: 8px;
  margin-top: 1.5rem;
  padding: 1.5rem 1.5rem .75em;

  h4 {
    margin: 0;
  }

  pre {
    margin: 0.25rem 0;
  }
`;
