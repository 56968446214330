import { useState, type ReactNode } from "react";
import * as Styled from "./styled";
import { ReactComponent as Chevron } from "@assets/icons/arrow-down.svg";

type Props = {
  heading: string;
  headingComponent?: ReactNode;
  small?: boolean;
  startOpen?: boolean;
  children: ReactNode;
};

const Accordion = ({
  heading,
  headingComponent,
  children,
  startOpen,
  small,
}: Props) => {
  const [open, setOpen] = useState(startOpen);
  return (
    <Styled.Accordion>
      <Styled.HeadingSection
        onClick={() => setOpen((prev) => !prev)}
        open={open}
      >
        {headingComponent ? (
          headingComponent
        ) : small ? (
          <span>{heading}</span>
        ) : (
          <h2>{heading}</h2>
        )}
        <Chevron />
      </Styled.HeadingSection>
      <Styled.HiddenSection open={open}>{children}</Styled.HiddenSection>
    </Styled.Accordion>
  );
};

export default Accordion;
