import { typography } from "@/styles/typography";
import styled from "styled-components";

export const DocLayout = styled.div`
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 120px);
  background: ${(props) => props.theme.colors.lightest};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  align-items: flex-start;
  ${(props) => props.theme.media.medium} {
    flex-direction: row;
  }
`;

export const Sidebar = styled.div`
  order: 1
  top: 7.5rem;
  left: 0;
  width: 100%;
  padding: 1rem;
  flex-shrink: 0;
  font-size: 0.875rem;
  ${(props) => props.theme.media.medium} {
    position: sticky;
    top: 120px;
    height: calc(100vh - 7.5rem);
    overflow-y: auto;
    width: 300px;
    padding: 1rem 2rem;

    /* Scrollbars for firefox */
    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.colors.light} transparent;

    /* Scrollbars for chrome/safari */
    &::-webkit-scrollbar {
      width: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.colors.light};
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #ecebed;
    }
  }
`;

export const DocHeading = styled.h1`
 font-size: 1.5rem;
   
    }
`;

export const DocContent = styled.div`
  order: 2;
  padding: 1rem;
  flex: 1;
  }
`;

export const DocInnerContent = styled.div`
  ${typography};
  h3 {
    scroll-margin-top: 130px;
    scroll-snap-margin-top: 130px; /* iOS 11 and older */
  }
  pre {
    padding: 1rem;
    border-radius: 0.5rem;
    background: ${(props) => props.theme.colors.light};
    code {
      padding: 0;
    }
  }
  code {
    color: ${(props) => props.theme.colors.accent2};
    background: ${(props) => props.theme.colors.light};
    border-radius: 0.25rem;
    padding: 0 0.2rem;
  }
  figure {
    margin-left: 0;
    margin-right: 0;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const AnchorSidebar = styled(Sidebar)`
  order: 1;
  display: block;
  right: 0;
  left: unset;
  ${(props) => props.theme.media.medium} {
    order 3;
    width: 200px;
    display: block;
    position: sticky;
    top: 120px;
  }
  ${(props) => props.theme.media.large} {
    width: 250px;
  }
`;

export const AnchorHeading = styled.h4`
  font-size: 0.875rem;
  line-height: 1.188rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.accent1};
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const Download = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  svg {
    width: 2rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
  }
`;