import styled from "styled-components";

export const SLAColor = styled.div<{ color?: string }>`
  background-color: ${(props) => props.color || "transparent"};
  text-align: ${(props) => (props.color ? "center" : "left")};
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 25px;
  column-gap: 4px;
  min-width: 5rem;
`;

export const ProgressCircle = styled.div<{ color?: string }>`
  content: '';
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color || "transparent"};
  border-radius: 50%;
  margin-left: 5px;
`;
