import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "styled-components";
import theme from "../styles/secclTheme";
import GlobalStyles from "../styles/globalStyles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, useNavigate } from "react-router-dom";
import config from "@/config";
import ScrollToTop from "./ScrollToTop";
import Analytics from "./Analytics";

// react-query (Tanstack) setup
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
    },
  },
});

const AppProviders = ({ children }: React.PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        <Auth0ProviderWithNavigation>
          <Analytics />
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            {children}
          </ThemeProvider>
        </Auth0ProviderWithNavigation>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default AppProviders;

const Auth0ProviderWithNavigation = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  return (
    <Auth0Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CUSTOMER_HUB_CLIENT_ID}
      authorizationParams={{
        redirect_uri: config.AUTH0_REDIRECT_URL,
      }}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || window.location.pathname);
      }}
    >
      {children}
    </Auth0Provider>
  );
};