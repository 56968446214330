import { AnchorHeading } from "@/components/layout/DocLayout/styled";
import type { Update } from "@/services/api/types";
import styled, { css } from "styled-components";

export const categoryBorder = css<{ $category?: Update["category"] }>`
  border-left: 4px solid;
  ${(props) =>
    props.$category === "Release notes" &&
    `
    border-color: ${props.theme.colors.highlight3};
`}
  ${(props) =>
    props.$category === "General notification" &&
    `
    border-color: ${props.theme.colors.accent2};
`}
${(props) =>
    props.$category === "Urgent notification" &&
    `
    border-color: ${props.theme.colors.error};
`}

${(props) =>
    props.$category === "Roadmap update" &&
    `
  border-color: ${props.theme.colors.highlight1};
`}
`;

export const UpdatesList = styled.div`
  padding-bottom: 1rem;
`;

export const Heading = styled.h2`
  margin: 0;
  padding: 1.8rem;
  padding-bottom: 1.5rem;
`;

export const Layout = styled.div`
  padding: 1.8rem;
  gap: 4rem;
  border-top: 1px solid ${(props) => props.theme.colors.light};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  align-items: flex-start;
  ${(props) => props.theme.media.medium} {
    flex-direction: row;
  }
`;

export const List = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const FilterBar = styled.div`
  width: 230px;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const ListItem = styled.div<{
  $category: Update["category"];
}>`
  ${categoryBorder};
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ListItemTitle = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Date = styled.span`
  color: ${(props) => props.theme.colors.utility};
  font-weight: 300;
`;

export const FilterHeading = styled(AnchorHeading)``;

export const FilterSectionHeading = styled.strong`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: block;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const FilterItem = styled.span<{
  $active?: boolean;
  $category?: Update["category"];
}>`
  cursor: pointer;
  margin-top: 0.8rem;
  display: block;
  ${(props) => props.$category && categoryBorder};
  padding-left: ${(props) => (props.$category ? "0.5rem" : 0)};
  font-weight: ${(props) => (props.$active ? "bold" : "normal")};
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
  }
`;
