import type { ThemeBreakpoints } from "@/types/theme";
import { useState, useEffect } from "react";
import { useTheme } from "styled-components";

const formatMediaString = (query: string) => query.split("@media ")[1];

export const useMediaQuery = (breakpoint: ThemeBreakpoints) => {
  const { media } = useTheme();
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const currentMedia = window.matchMedia(
      formatMediaString(media[breakpoint])
    );
    if (currentMedia.matches !== matches) {
      setMatches(currentMedia.matches);
    }
    const listener = () => {
      setMatches(currentMedia.matches);
    };
    currentMedia.addEventListener("change", listener);
    return () => currentMedia.removeEventListener("change", listener);
  }, [matches, breakpoint, media]);

  return matches;
};
