import { ErrorBoundary } from "react-error-boundary";
import ErrorScreen from "@/components/shared-ui/ErrorScreen";
import { useLocation } from "react-router-dom";
import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  handleError: (error: Error) => void;
};

const CustomErrorBoundary = ({ children, handleError }: Props) => {
  const location = useLocation();

  return (
    <ErrorBoundary key={location.pathname} fallback={<ErrorScreen />} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
