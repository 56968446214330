import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import Card from "@/components/shared-ui/Card";
import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import { ReactComponent as Chevron } from "@assets/icons/arrow-down.svg";
import { ReactComponent as Stopwatch } from "@assets/icons/stopwatch-icon.svg";
import ContactCard from "@/components/shared-ui/ContactCard";
import ReactPlayer from "react-player/youtube";

const SystemTraining = () => {
  const api = useApi();
  const { videoSlug } = useParams();

  const { data: training } = useQuery(["get-all-system-training"], () =>
    api.systemTraining.getAll()
  );

  const currentTrainingSection = training?.find((item) =>
    item.videos.find((video) => videoSlug === video.slug)
  );

  const currentVideo = currentTrainingSection?.videos.find(
    (video) => videoSlug === video.slug
  );

  if (!training || !currentTrainingSection || !currentVideo) {
    return <LoadingScreen />;
  }

  return (
    <Page title="System training">
      <Styled.SystemTraining>
        <Styled.SidebarCard>
          <Styled.Title>System training</Styled.Title>
          {training.map((item) => (
            <Styled.SidebarSection
              key={item.id}
              $active={currentTrainingSection.id === item.id}
            >
              <Styled.SidebarHeadingLink
                to={`/system-training/${item.videos[0].slug}`}
              >
                <Styled.SidebarHeading>{item.title}</Styled.SidebarHeading>
                <Chevron />
              </Styled.SidebarHeadingLink>
              <Styled.SidebarSubSection>
                {item.videos.map((video) => (
                  <Styled.SidebarItem
                    key={video.slug}
                    $active={
                      currentTrainingSection.id === item.id &&
                      currentVideo?.title === video.title
                    }
                    to={`/system-training/${video.slug}`}
                  >
                    {video.title}
                    <Styled.Duration>
                      <Stopwatch /> {video.duration}
                    </Styled.Duration>
                  </Styled.SidebarItem>
                ))}
              </Styled.SidebarSubSection>
            </Styled.SidebarSection>
          ))}
        </Styled.SidebarCard>
        <Card noPadding gridColumn={[1, 2]}>
          <Styled.VideoCard>
            <Styled.VideoWrapper>
              <ReactPlayer
                url={currentVideo.youtube_url}
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
                config={{ playerVars: { color: "white" } }}
              />
            </Styled.VideoWrapper>
            <Styled.Title>{currentVideo.title}</Styled.Title>
            <p>{currentVideo.description}</p>
          </Styled.VideoCard>
        </Card>
        <ContactCard />
      </Styled.SystemTraining>
    </Page>
  );
};

export default SystemTraining;
