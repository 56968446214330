import styled from "styled-components";
import {
  color,
  flexbox,
  layout,
  space,
  type LayoutProps,
  type SpaceProps,
  type FlexboxProps,
} from "styled-system";
import type { ThemeColors } from "../../../types/theme";

interface CustomColorProps {
  color?: ThemeColors;
  backgroundColor?: ThemeColors;
}

export interface FlexContainerProps
  extends CustomColorProps,
    LayoutProps,
    SpaceProps,
    FlexboxProps {
  children: React.ReactNode;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
`;
