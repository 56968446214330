import type { GridProps, MarginProps } from "styled-system";
import * as Styled from "./styled";
import { Link } from "react-router-dom";
import type { ThemeGradients } from "@/types/theme";

interface Props extends MarginProps, GridProps {
  children: React.ReactNode;
  link?: string;
  noPadding?: boolean;
  featureBackground?: { full?: boolean; gradient: ThemeGradients };
}

const Card = ({
  children,
  link,
  noPadding,
  featureBackground,
  ...props
}: Props) => {
  const cardCode = (
    <Styled.Card
      $isLink={!!link}
      $noPadding={noPadding}
      $featureBackground={
        featureBackground?.full ? featureBackground.gradient : undefined
      }
      $noShadow={featureBackground?.full}
      {...props}
    >
      <Styled.InnerContainer
        $featureBackground={
          featureBackground?.full ? undefined : featureBackground?.gradient
        }
        $noPadding={noPadding}
      >
        {children}
      </Styled.InnerContainer>
    </Styled.Card>
  );

  if (link) {
    return <Link to={link}>{cardCode}</Link>;
  } else {
    return cardCode;
  }
};

export default Card;
