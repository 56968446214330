import styled from "styled-components";

export const Form = styled.form`
  margin-top: 1.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
`;
