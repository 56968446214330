import styled from "styled-components";

export const EscalationPoint = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactRow = styled.div`
  display: block;
`;

export const Name = styled.span`
  font-weight: 500;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors.accent2};
  font-weight: 500;
`;
