import type { Company } from "@/services/api/types";

export const sortCompanies = (companies: Company[]) => {
  companies.sort((a, b) => {
    if (a.company_name < b.company_name) return -1;
    if (a.company_name > b.company_name) return 1; 
    return 0;
  });

  return companies.map((company) => ({
    id: company.id,
    label: company.company_name,
  })); 
};
