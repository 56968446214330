import { createContext, useContext, useEffect, useState } from "react";
import axios, { type CreateAxiosDefaults } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { api, type ApiType } from "@/services/api/endpoints";
import config from "@/config";

type ApiContext = (ApiType & { refresh: () => void }) | null;
const ApiContext = createContext<ApiContext>(null);

const createApiClient = (options: CreateAxiosDefaults) => {
  const client = axios.create(options);
  const newApi = api.init(client);
  return newApi;
};

export const ApiProvider = ({ children }: React.PropsWithChildren) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState<string | undefined>();
  const [refresh, setRefresh] = useState<"default" | "true" | "false">(
    "default"
  );

  useEffect(() => {
    async function getToken() {
      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.AUTH0_AUDIENCE,
        },
        // Turn off cache if this is manually refreshed
        cacheMode: refresh === "true" ? "off" : "on",
      });
      setToken(authToken);
      setRefresh("false");
    }
    isAuthenticated && refresh !== "false" && getToken();
  }, [getAccessTokenSilently, isAuthenticated, refresh, setRefresh]);

  // Trigger a new token to be generated from a component.
  const triggerRefresh = () => {
    setRefresh("true");
  };

  if (!token) {
    return;
  }

  const options = {
    baseURL: "/api/",
    timeout: 5000,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const api = createApiClient(options);

  return (
    <ApiContext.Provider value={{ ...api, ...{ refresh: triggerRefresh } }}>
      {children}
    </ApiContext.Provider>
  );
};

const useApi = () => {
  const ApiState = useContext(ApiContext);
  if (ApiState === null) {
    throw new Error(
      "Called `useApiState` outside the scope of ApiContext.provider"
    );
  }
  return ApiState;
};

export default useApi;
