import { Card } from "@/components/shared-ui/Card/styled";
import styled from "styled-components";

export const Tasks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  ${Card} {
    min-width: 0;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;