import styled from "styled-components";

export const ActionMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  svg {
    height: 1rem;
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.colors.accent1};
    }
  }
`;
