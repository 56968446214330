import styled, { css } from "styled-components";

export const MultiSelectDropdown = styled.div<{ $compact?: boolean }>`
  position: relative;
  width: 100%;
`;

export const TitleContainer = styled.div<{ $active: boolean; $alt?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.375rem;
  color: inherit;
  background: ${(props) =>
    props.$alt ? props.theme.colors.lightest : props.theme.colors.light};
  border-radius: ${(props) =>
    props.$active ? "0.25rem 0.25rem 0 0" : "0.25rem"};
  border: none;
  border 1px solid ${(props) => props.theme.colors.dark}; 
  padding: 0.5rem 0.938rem;
  padding-right: 10px;
  margin: 0;
  outline-color: fade-out(${(props) => props.theme.colors.highlight2}, 0.5);
  transition:
    box-shadow 0.2s,
    outline 0.2s;
  width: 100%;
  cursor: pointer;
  &:focus {
    box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.highlight2};
  }
  svg {
    width: 10px;
    height: 10px;
  }
`;

export const OptionsContainer = styled.div<{
  $active: boolean;
  $alt?: boolean;
}>`
  position: absolute;
  width: 100%;
  border 1px solid ${(props) => props.theme.colors.dark}; 
  border-top: 0; 
  border-radius: 0 0 0.25rem 0.25rem;
  opacity: ${(props) => (props.$active ? 1 : 0)};
  transform: ${(props) => (props.$active ? "none" : "scaleY(0)")};
  transform-origin: center top;
  transition: transform 0.1s, opacity: 0.2s, -webkit-transform: 0.2s;
  will-change: transform;
  z-index: 20;
  display: flex;
  flex-direction: column;
  `;

export const Option = styled.div<{ $alt?: boolean; $disabled?: boolean }>`
  display: flex;
  background: ${(props) =>
    props.$alt ? props.theme.colors.lightest : props.theme.colors.light};
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.utility};
  &:last-of-type {
    border-bottom: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  &:hover {
    filter: brightness(0.8);
  }
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  svg {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
  }
  ${(props) =>
    props.$disabled &&
    css`
      background: #e7e6e8;
      &:hover {
        filter: unset;
      }
    `}
`;

export const Wrapper = styled.div<{ $compact?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.$compact ? "row" : "column")};
  align-items: ${(props) => (props.$compact ? "center" : "flex-start")};
  padding-bottom: ${(props) => (props.$compact ? "0" : "1rem")};
`;

export const Label = styled.label<{ $compact?: boolean }>`
  font-weight: 700;
  flex-shrink: 0;
  padding-bottom: ${(props) => (props.$compact ? "0" : "0.3rem")};
  padding-right: ${(props) => (props.$compact ? "0.5rem" : "0")};
`;
