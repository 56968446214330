import * as Styled from "./styled";

type Props = {
  label?: string;
  isChecked: boolean;
  handleToggle: () => void;
};

const Toggle = ({ label, isChecked, handleToggle }: Props) => {
  return (
    <Styled.ToggleWrapper>
      <Styled.Label htmlFor="checkbox">{label}</Styled.Label>
      <Styled.Checkbox
        id="checkbox"
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
      />
      <Styled.ToggleLabel htmlFor="checkbox" $checked={isChecked}>
        <Styled.ToggleButton $checked={isChecked} />
      </Styled.ToggleLabel>
    </Styled.ToggleWrapper>
  );
};

export default Toggle;
