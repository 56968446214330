import { useParams } from "react-router-dom";
import * as Styled from "./styled";
import Page from "@/components/layout/Page";
import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import { ReactComponent as Chevron } from "@assets/icons/arrow-down.svg";
import BlockRenderer from "@/components/shared-ui/BlockRenderer";
import type { DirectusBlockText } from "@/services/api/types";
import useDocsActiveHeading from "@/contexts/DocsActiveHeadingContext";
import DocLayout from "@/components/layout/DocLayout";

const Documentation = () => {
  const api = useApi();
  const { activeId } = useDocsActiveHeading();
  const { docSlug, sectionSlug } = useParams();
  const { data: docs } = useQuery(["get-docs"], () =>
    api.documentation.getAll()
  );

  const currentDoc = docs?.find((doc) => doc.slug === docSlug);

  if (!docs || !currentDoc) {
    return <LoadingScreen />;
  }

  const currentSection = currentDoc?.content_sections.find(
    (section) => section.slug === sectionSlug
  );

  // Get all text blocks
  const textBlocks = currentSection?.content.filter(
    (block): block is DirectusBlockText => block.collection === "text_block"
  );
  // Get an array of all level 3 headings
  const levelThreeHeadings = textBlocks?.reduce((accumulator, currentValue) => {
    const h3Blocks = currentValue.item.text_content.blocks.filter(
      (item) => item.type === "header" && item.data.level === 3
    );
    h3Blocks.forEach((block) => accumulator.push(block.data.text));
    return accumulator;
  }, [] as string[]);

  const docsMenu = docs.map((doc) => (
    <Styled.SidebarSection key={doc.id} $active={currentDoc.id === doc.id}>
      <Styled.SidebarHeadingLink
        to={`/docs/${doc.slug}/${doc.content_sections[0].slug}`}
      >
        <Chevron />
        <Styled.SidebarHeading>
          {doc.title}
          {doc.content_sections.some((section) => section.new) && (
            <Styled.NewTag>New</Styled.NewTag>
          )}
        </Styled.SidebarHeading>
      </Styled.SidebarHeadingLink>
      <Styled.SidebarSubSections>
        {doc.content_sections.map((section) => {
          return (
            <Styled.SidebarItem
              key={section.slug}
              $active={
                currentDoc.id === doc.id &&
                currentSection?.title === section.title
              }
            >
              <Styled.SidebarLink to={`/docs/${doc.slug}/${section.slug}`}>
                {section.title}{" "}
                {section.new && <Styled.NewTag>New</Styled.NewTag>}
              </Styled.SidebarLink>
            </Styled.SidebarItem>
          );
        })}
      </Styled.SidebarSubSections>
    </Styled.SidebarSection>
  ));

  const anchorMenu = levelThreeHeadings?.map((heading) => {
    const anchorSuitableId = heading.replace(/\s+/g, "-").toLowerCase();
    return (
      <Styled.AnchorItem
        key={anchorSuitableId}
        href={`#${anchorSuitableId}`}
        $active={activeId === anchorSuitableId}
      >
        {heading}
      </Styled.AnchorItem>
    );
  });

  const docContent = (
    <>{currentSection && <BlockRenderer blocks={currentSection.content} />} </>
  );

  return (
    <Page
      title="Documentation"
      noPadding
      fullWidth
      stickyBreadcrumbs
      searchIndexPriority="docs"
    >
      <DocLayout
        primarySidebar={{
          visible: true,
          menu: docsMenu,
          accordionHeading: currentDoc.title,
        }}
        secondarySidebar={{
          visible: levelThreeHeadings && levelThreeHeadings.length > 0,
          heading: "On this page",
          menu: anchorMenu,
        }}
        docHeading={currentSection ? currentSection.title : currentDoc.title}
        docContent={docContent}
      />
    </Page>
  );
};

export default Documentation;
