import styled from "styled-components";

export const Accordion = styled.div`
  width: 100%;
  overflow-x: auto;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const HeadingSection = styled.div<{ open?: boolean }>`
  h2 {
    font-size: 1.5rem;
  }
  padding: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  svg {
    width: 0.6rem;
    flex-shrink: 0;
    ${(props) => props.open && `transform: rotate(180deg);`}
  }
  h2 {
    margin: 0;
  }
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
    svg {
      color: ${(props) => props.theme.colors.accent1};
    }
  }
`;

export const HiddenSection = styled.div<{ open?: boolean }>`
  width: 100%;
  padding: 1rem 0;
  display: ${(props) => (props.open ? "block" : "none")};
`;
